import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getManageProfile, manageProfileMark ,handleErrorMessages} from '../actions/actions';
import { LOADING, GET_WATCH_PROFILE, GET_MANAGER_PROFILE, MANAGE_WATCH_MARK, MANAGE_MANAGER_MARK, SUCCESS, ERROR } from '../actions/types';
import { checkValue } from '../functions/functions';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../styles/manageProfile.css';

class ManageProfile extends Component{
    constructor(props){
        super(props);
        this.state = {
            mark: {
                method: 'TEXTBOX',
                product: 'WATCH',
                profile_id: 'select-profile',
                operation: 'ADD',
                subscription: 'select-subscription',
                application_numbers: '',
                file: null,
                focus_words: 'AUTO',
                focus_classes: 'AUTO',
                tags: false,
                mm_type: 'trademark',
            },
        };
    }

    componentDidMount(){
        if(!localStorage.getItem('token')){
            this.props.history.push('/');
        } else {
            this.props.getManageProfile(GET_WATCH_PROFILE, 'WATCH')
            this.props.getManageProfile(GET_MANAGER_PROFILE, 'MANAGER')
        }
    }

    handleMarkChange(preference, userVariable, value) {
        this.props.handleErrorMessages();
        this.setState({
            [preference]: {
                ...this.state[preference],
                [userVariable]: value
            }
        });
    }

    handleClearError() {
        this.props.handleErrorMessages();
    }
    
    async handleSubmit() {     
        const { mark } = this.state;
        let data = new FormData();
        data.append('method', mark.method);
        data.append('product', ((mark.product === 'MANAGER') || (mark.product === 'PATENT')) ? 'MANAGER' : mark.product);
        data.append('profile_id', mark.profile_id);
        data.append('subscription', mark.subscription);
        if (mark.method === 'TEXTBOX') {
            data.append('application_numbers', mark.application_numbers);
            data.append('operation', mark.operation);
            data.delete('file');
            data.delete('focus_words');
            data.delete('focus_classes');
            if (mark.product === 'WATCH') {
                data.delete('mm_type');
            } else if (mark.product === 'MANAGER') {
                data.append('mm_type', mark.mm_type);
            }
            if ((mark.application_numbers.length > 0) && (mark.profile_id !== 'select-profile') && (mark.subscription !== 'select-subscription')) {
                await this.props.manageProfileMark(
                    (mark.product === 'WATCH') ? MANAGE_WATCH_MARK : ((mark.product === 'MANAGER') || (mark.product === 'PATENT')) ? MANAGE_MANAGER_MARK : '' ,
                    data
                );                
            } else {
                alert('Please fill all required fields')
            }
        } else if (mark.method === 'EXCEL') {
            data.append('operation', 'ADD');
            data.append('file', checkValue(mark.file) ? mark.file : null);
            data.append('tags', mark.tags);
            data.delete('application_numbers');
            if (mark.product === 'WATCH') {
                data.append('focus_words', mark.focus_words);
                data.append('focus_classes', mark.focus_classes);
                data.delete('mm_type');
                if(mark.subscription === 'TM PROTECT') {
                    data.delete('focus_words');
                    data.delete('focus_classes'); 
                }
            } else if (mark.product === 'MANAGER') {
                data.append('mm_type', mark.mm_type);
                data.delete('focus_words');
                data.delete('focus_classes');
            }
            if ((mark.file !== null) && (mark.profile_id !== 'select-profile') && (mark.subscription !== 'select-subscription')) {
                await this.props.manageProfileMark(
                    (mark.product === 'WATCH') ? MANAGE_WATCH_MARK : ((mark.product === 'MANAGER') || (mark.product === 'PATENT')) ? MANAGE_MANAGER_MARK : '' ,
                    data
                );
            } else {
                alert('Please fill all required fields')
            }
        }
    }

    render(){
        const { 
            method,
            product,
            profile_id,
            subscription,
            operation,
            application_numbers,
            focus_words,
            focus_classes,
            tags,
            mm_type
        } = this.state.mark;
        const { 
            watchProfile,
            managerProfile, 
            manageWatchMark, 
            manageManagerMark,
            manageWatchMarkStatus,
            manageManagerMarkStatus
        } = this.props;
        let profileList;
        let message;
        let status;
        let subsciption_option;
        if (product === 'WATCH') {
            profileList = (checkValue(watchProfile) ? watchProfile.profiles : [])
            message = (manageWatchMark && manageWatchMark.message)
            status = manageWatchMarkStatus
            subsciption_option = 
            (<>
                <option key='TRADEMARK WATCH' id='trademark_watch-id' value="TRADEMARK WATCH">Trademark Watch</option>
                {(method === 'EXCEL') && 
                <>
                    <option key='PSEUDO TRADEMARK WATCH' id='pseudo_trademark-id' value="PSEUDO TRADEMARK WATCH">Pseudo Trademark Watch</option>
                    <option key='TM PROTECT' id='tm_protect-id' value="TM PROTECT">TM Protect</option>
                </>
                }
            </>)
        } 
        else if (product === 'MANAGER') {
            profileList = (checkValue(managerProfile) ? managerProfile.profiles : [])
            message = (manageManagerMark && manageManagerMark.message)
            status = manageManagerMarkStatus
            subsciption_option = 
            (<>
                <option key='TRADEMARK' id='trademark-id' value="TRADEMARK">Trademark</option>
                {(method === 'EXCEL') && 
                <>
                    <option key='PSEUDO TRADEMARK' id='pseudo_trademark-id' value="PSEUDO TRADEMARK">Pseudo Trademark</option>
                    <option key='CUSTOM TRADEMARK' id='custom_trademark-id' value="CUSTOM TRADEMARK">Custom Trademark</option>
                    <option key='INTERNATIONAL TRADEMARK' id='international_trademark-id' value="INTERNATIONAL TRADEMARK">International Trademark</option>
                </>
                }
            </>)
        }
        else if (product === 'PATENT') {
            profileList = (managerProfile && managerProfile.profiles)
            message = (manageManagerMark && manageManagerMark.message)
            status = manageManagerMarkStatus
            subsciption_option = 
            (<>
                <option key='PATENT' id='patent-id' value="PATENT">Patent</option>
                {(method === 'EXCEL') && 
                <>
                    <option key='PSEUDO PATENT' id='pseudo_patent-id' value="PSEUDO PATENT">Pseudo Patent</option>
                    <option key='CUSTOM PATENT' id='custom_patent-id' value="CUSTOM PATENT">Custom Patent</option>
                    <option key='INTERNATIONAL PATENT' id='international_patent-id' value="INTERNATIONAL PATENT">International Patent</option>
                </>
                }
            </>)
        }
        
        return(
            <div className="manage-profile">
                <div className="manage-profile-container">
                    <div className="alignR">
                        <div className="manage-profile-dropdown">
                            <select value={method} onChange={(event)=> this.handleMarkChange('mark', 'method', event.target.value)}>
                                <option value="TEXTBOX">Text</option>
                                <option value="EXCEL">Excel Upload</option>
                            </select>
                        </div>
                        <div className="manage-profile-dropdown">
                            <select value={product} onChange={(event)=> this.handleMarkChange('mark', 'product', event.target.value)}>
                                <option value="WATCH">Trademark Watch</option>
                                <option value="MANAGER">Trademark Manager</option>
                                <option value="PATENT">Patent Manager</option>
                            </select>
                        </div>
                    </div>
                    <div className="mark-profile-section">
                    <div className="mark-container-profile">
                        <form>
                            <div className="mark-list">
                                <div className="mark-list-item">
                                    <label>Profile:- </label>
                                </div>
                                <div className="mark-list-item">
                                    <select 
                                        value={profile_id}
                                        onChange={(event)=> this.handleMarkChange('mark', 'profile_id', event.target.value)}>
                                        <option value="select-profile">select profile</option>
                                        {profileList.length > 0 && profileList.map( eachPropfileList=> 
                                            <option key={eachPropfileList.id} id={`profile-${eachPropfileList.id}`} value={eachPropfileList.id}>{eachPropfileList.email}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="mark-list">
                                <div className="mark-list-item">
                                    <label>Subscription:- </label>
                                </div>
                                <div className="mark-list-item">
                                    <select value={subscription} onChange={(event)=> this.handleMarkChange('mark', 'subscription', event.target.value)}>
                                        <option value="select-subscription">select-subscription</option>
                                        {subsciption_option}
                                    </select>
                                </div>
                            </div>
                            {(product === 'MANAGER') &&
                                <div className={`mark-list ${((subscription === 'CUSTOM TRADEMARK') || (subscription === 'INTERNATIONL TRADEMARK')) ? 'display_none': ''}`}>
                                    <div className="mark-list-item">
                                        <label>Management Mark Type:- </label>
                                    </div>
                                    <div className="mark-list-item">
                                        <select value={mm_type} onChange={(event)=>this.handleMarkChange('mark', 'mm_type', event.target.value)}>
                                            <option key='trademark-add' id='trademark-add-id' value='trademark'>Your-Trademark</option>
                                            <option key='opposed-add' id='opposed-add-id' value='opposed'>Opposed-Trademark</option>
                                            <option key='tracker-add' id='tracker-add-id' value='tracker'>Status Tracker</option>
                                        </select>
                                    </div>
                                </div>
                            }
                            {(method === 'TEXTBOX') && 
                                <>
                                    <div className="mark-list">
                                        <div className="mark-list-item">
                                            <label>Operation:- </label>
                                        </div>
                                        <div className="mark-list-item">
                                            <select value={operation} onChange={(event)=> this.handleMarkChange('mark', 'operation', event.target.value)}>
                                                <option value="ADD">Add</option>
                                                <option value="DELETE">Delete</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mark-list">
                                        <div className="mark-list-item">
                                            <label>Enter Mark:- </label>
                                        </div>
                                        <div className="mark-list-item">
                                            <textarea 
                                                value={`${application_numbers}`}
                                                onChange={(event)=> this.handleMarkChange('mark', 'application_numbers', event.target.value)}>
                                                {`${application_numbers}`}
                                            </textarea>
                                        </div>
                                    </div>
                                </>
                            }
                            {(method === 'EXCEL') && 
                                <>
                                    <div className="mark-list">
                                        <div className="mark-list-item">
                                            <label>Excel Upload:- </label>
                                        </div>
                                        <div className="mark-list-item">
                                            <input 
                                                type="file" 
                                                id="excel_upload-id" 
                                                name="excel_upload" 
                                                onChange={(event) => this.handleMarkChange('mark', 'file', event.target.files[0])} 
                                                placeholder="Upload Excel File" 
                                                accept=".xls, .xlsx"
                                            />
                                        </div>
                                    </div>
                                    <div className="mark-list">
                                        {(product === 'WATCH') && (subscription !== 'TM PROTECT') &&
                                            <>
                                                <div>
                                                    <div className="mark-list-item">
                                                        <label>Add Focus Words:- </label>
                                                    </div>
                                                    <div className="mark-list-item">
                                                        <select value={focus_words} onChange={(event)=> this.handleMarkChange('mark', 'focus_words', event.target.value)}>
                                                            <option key='focus_words_auto' id='focus_words_auto-id' value='AUTO'>Auto</option>
                                                            <option key='focus_words_excel' id='focus_words_excel-id' value='EXCEL'>Excel</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="mark-list-item">
                                                        <label>Add Focus Classes:- </label>
                                                    </div>
                                                    <div className="mark-list-item">
                                                        <select value={focus_classes} onChange={(event)=> this.handleMarkChange('mark', 'focus_classes', event.target.value)}>
                                                            <option key='focus_classes_auto' id='focus_classes_auto-id' value='AUTO'>Auto</option>
                                                            <option key='focus_classes_excel' id='focus_classes_excel-id' value='EXCEL'>Excel</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div>
                                            <div className="mark-list-item">
                                                <label>Add Tags:- </label>
                                            </div>
                                            <div className="mark-list-item">
                                                <input
                                                    type="checkbox" 
                                                    checked={tags}
                                                    onChange={(event)=> this.handleMarkChange('mark', 'tags', event.target.checked)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="mark-list">
                                <div className="mark-list-item">
                                </div>
                                <div className="mark-list-item">
                                </div>
                            </div>
                            <div className="mark-list-profile">
                                <button 
                                    disabled={status === LOADING}
                                    type="button" 
                                    className="mark-list-button" 
                                    onClick={()=>this.handleSubmit()}>
                                    {status === LOADING ? 'Loading...':'Submit'}
                                </button>
                              
                                    {this.props?.errors?.length > 0 ?
                                        <button type="button" onClick={() => this.handleClearError()} style={{ marginLeft: '20px', color: 'white', backgroundColor: 'red' }}>
                                            Clear Errors
                                        </button> : ''}

                            </div>
                            </form>  
                        </div>
                      
                        <div className="upload-status-profile">
                            <h3 >UPLOAD STATUS</h3>
                               {status===LOADING?<CircularProgress />:''}
                                <h4 style={{ color: (this.props.manageManagerMarkStatus === SUCCESS || this.props.manageWatchMarkStatus === SUCCESS) ? 'green' : 'red' }}>{this.props.message}</h4>
                                {(this.props.manageManagerMarkStatus === ERROR || this.props.manageWatchMarkStatus === ERROR) ?
                                <div
                                    style={{ height: this.props.scrollerHeightStatus === SUCCESS ? '0px' : '400px', overflowY: 'scroll' }}
                                >
                                        {(this.props.manageManagerMarkStatus === ERROR || this.props.manageWatchMarkStatus === ERROR) && this.props?.errors?.length > 0 ?
                                            this.props.errors.map((err, idx) => {
                                                return (
                                                    <div style={{display:'flex',padding:'5px'}}>
                                                    <p>{idx}.</p><p key={idx} style={{ color: 'red', }}>{err}</p>
                                                    </div>
                                                )
                                            }
                                            ) : ''}
                                    </div> : ''}
                            </div>
                         </div>   
                    </div>
            </div>
        )
    }
}

const mapDispatchToProps = {
    getManageProfile,
    manageProfileMark,
    handleErrorMessages,
};

function mapStateToProps(state){
    const {
        watchProfile,
        watchProfileStatus,
        managerProfile,
        managerProfileStatus,

        manageWatchMark,
        manageWatchMarkStatus,
        manageManagerMark,
        manageManagerMarkStatus,
        message,
        errors,
        scrollerHeightStatus,
    } = state.manageProfile
    
    console.log("scrollerHeight", scrollerHeightStatus,errors) 
    
    return ({
        watchProfile,
        watchProfileStatus,
        managerProfile,
        managerProfileStatus,

        manageWatchMark,
        manageWatchMarkStatus,
        manageManagerMark,
        manageManagerMarkStatus,
        message,
        errors,
        scrollerHeightStatus
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageProfile);