import { AUTHENTICATE } from '../actions/types';

export default function(state = {
    authenticate: false
}, action){
    switch(action.type){
        case AUTHENTICATE:
            return {...state, authenticate: action.payload};
        default:
            return state;
    }
}