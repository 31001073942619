//--------LIBRARIES--------
import React, { Component } from 'react';
import { connect } from 'react-redux';

//--------ACTIONS--------
import { createWhatsNew, postChangeAlert, getChangeAlert } from '../actions/actions';

//--------CUSTOM FUNCTIONS--------
import { checkValue } from "../functions/functions";

const alertOptions = ["sign_in", "search", "watch", "manager"];

class WhatsNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            whatsNew: {
                messageText: true,
                type: 'new',
                title: '',
                content: '',
                date_of_update: '',
                image: null,
            },
            alertChange: {
                message: "",
                alert_source: []
            }
        };
    }

    componentDidMount() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        } else {
            this.props.getChangeAlert();
        }
    }

    handleWhatsNewChange(userVariable, value, event) {
        event.preventDefault()
        this.setState({
            whatsNew: {
                ...this.state.whatsNew,
                [userVariable]: value
            }
        })
    }

    handleAlertChange(userVariable, value, event) {
        event.preventDefault()
        this.setState({
            alertChange: {
                ...this.state.alertChange,
                [userVariable]: value
            }
        })
    }

    handleSubmit() {
        const { title, content, date_of_update } = this.state.whatsNew;
        if (title.length > 0 && content.length > 0 && date_of_update.length > 0) {
            this.props.createWhatsNew(this.state.whatsNew)
        } else {
            alert("please fill all filed");
        }
    }

    render() {
        return (
            <div className="Whats-New">
                {/* whats-new */}
                <div className="m-top-20">
                    <div className="whats-New-box">
                        <div>
                            <p className="whats-New-heading">Whats New</p>
                        </div>
                        <div>
                            <p className='whats-New-text'>{checkValue(this.props.whatsNewMessage) ? this.props.whatsNewMessage : ''}</p>
                        </div>
                        <ul className="whats-New-list">
                            <li>
                                <div>
                                    <label>Select Type:-</label>
                                </div>
                                <div>
                                    <select className="whats-new-input" value={this.state.whatsNew.type} onChange={(event) => this.handleWhatsNewChange('type', event.target.value, event)}>
                                        <option key='new' id='new-id' value='new'>New</option>
                                        <option key='upcoming' id='upcoming-id' value='upcoming'>Upcoming</option>
                                    </select>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <label>Enter Title:-</label>
                                </div>
                                <div>
                                    <input className="whats-new-input" value={this.state.whatsNew.title} type="text" onChange={(event) => this.handleWhatsNewChange('title', event.target.value, event)} placeholder="Enter Title" />
                                </div>
                            </li>
                            <li>
                                <div>
                                    <label>Enter Date:-</label>
                                </div>
                                <div>
                                    <input className="whats-new-input" value={this.state.whatsNew.date_of_update} type="date" onChange={(event) => this.handleWhatsNewChange('date_of_update', event.target.value, event)} placeholder="Enter date" />
                                </div>
                            </li>
                            <li>
                                <div>
                                    <label>Upload Image:-</label>
                                </div>
                                <div>
                                    <input className="whats-new-input" type="file" onChange={(event) => this.handleWhatsNewChange('image', event.target.files, event)} placeholder="Upload Image" />
                                </div>
                            </li>
                            <li>
                                <div>
                                    <label>Enter Content:-</label>
                                </div>
                                <div>
                                    <textarea className="whats-new-area" rows="3" cols="30" value={this.state.whatsNew.content} onChange={(event) => this.handleWhatsNewChange('content', event.target.value, event)} placeholder="Enter Content" />
                                </div>
                            </li>
                            <li>
                                <button className="whats-new-button" type="button" onClick={() => this.handleSubmit()}>Save</button>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* whats-new */}

                {/* Alert-Change */}
                <div className="m-top-20">
                    <div className="whats-New-box">
                        <div>
                            <p className="whats-New-heading">Alert Change</p>
                        </div>
                        <ul className="whats-New-list">
                            <li>
                                <div>
                                    <label>Select Source:-</label>
                                </div>
                                <div>
                                    {
                                        alertOptions.map(e => (
                                            <label key={e}>
                                                <input
                                                    type="checkbox"
                                                    value={e}
                                                    checked={this.state.alertChange.alert_source.includes(e)}
                                                    onChange={(event) => {
                                                        if (event.target.checked) {
                                                            this.setState({
                                                                alertChange: {
                                                                    ...this.state.alertChange,
                                                                    alert_source: [...this.state.alertChange.alert_source, event.target.value]
                                                                }
                                                            });
                                                        } else {
                                                            this.setState({
                                                                alertChange: {
                                                                    ...this.state.alertChange,
                                                                    alert_source: this.state.alertChange.alert_source.filter(e => event.target.value !== e)
                                                                }
                                                            });
                                                        }
                                                    }}
                                                />
                                                {e}
                                            </label>
                                        ))
                                    }
                                </div>
                            </li>
                            <li>
                                <div>
                                    <label>Enter Message:-</label>
                                </div>
                                <div>
                                    <input className="whats-new-input" value={this.state.alertChange.message} type="text" onChange={(event) => this.handleAlertChange('message', event.target.value, event)} placeholder="Enter Title" />
                                </div>
                            </li>
                            <li>
                                <button className="whats-new-button" type="button" onClick={() => this.props.postChangeAlert(this.state.alertChange)}>Save</button>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* Alert-Change */}

                {/* Alert-Change-Messages-Table */}
                <div className="m-top-20">
                    <h1 className="Table-Title">Alert Messages</h1>
                    <table className="Table">
                        <thead className="Table-Head">
                            <tr className="Table-Head-Row">
                                <th className="Table-Head-Cell">Alert Source</th>
                                <th className="Table-Head-Cell">Message</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.alertMessages.length>0 && this.props.alertMessages.map( eachAlertMessage => 
                                    <tr key={eachAlertMessage.alert_source} className="Table-Body-Row">
                                        <td className="Table-Body-Cell">{eachAlertMessage.alert_source}</td>
                                        <td className="Table-Body-Cell">{eachAlertMessage.message}</td>
                                    </tr>
                                )
                            }
                            {
                                this.props.alertMessages.length === 0 &&
                                <tr className="Table-Body-Row No-Record-Row">
                                    <td colSpan="2" className="Table-Body-Cell">No Alert Messages</td>
                                </tr>
                            }                        
                        </tbody>
                    </table>
                </div>
                {/* Alert-Change-Messages-Table */}
            </div>
        )
    }

    componentWillUnmount() {
        this.setState({
            intervalIndex: clearInterval(this.state.intervalIndex)
        });
    }
}

const mapDispatchToProps = {
    createWhatsNew,
    postChangeAlert,
    getChangeAlert
};

function mapStateToProps(state) {
    const {
        whatsNewMessage, alertMessages
    } = state.whatsNew;
    return {
        whatsNewMessage, alertMessages
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WhatsNew);