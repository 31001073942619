import { GET_WATCH_PROFILE, GET_MANAGER_PROFILE, MANAGE_WATCH_MARK, MANAGE_MANAGER_MARK, SUCCESS, ERROR,PROFILE_ERRORS_MESSAGES, LOADING } from '../actions/types';


const defaultState = {
    watchProfile: null,
    watchProfileStatus: null,
    managerProfile: null,
    managerProfileStatus: null, 

    manageWatchMark: null,
    manageWatchMarkStatus: null,
    manageManagerMark: null,
    manageManagerMarkStatus: null,
    message:'',
    errors: [],
    scrollerHeightStatus:null,
}

export default function (state = defaultState, action){
    switch (action.type) {  
        case GET_WATCH_PROFILE:
            return {
                ...state,
                watchProfile: action.payload,
                watchProfileStatus: action.status,
            };

        case GET_MANAGER_PROFILE:
            return {
                ...state,
                managerProfile: action.payload,
                managerProfileStatus: action.status
            }

        case MANAGE_WATCH_MARK:
            if (action.status === SUCCESS) {
                return {
                    ...state,
                    message: action?.payload?.message,
                    errors: action?.payload?.errors,
                    manageWatchMark: action.payload,
                    manageWatchMarkStatus: action.status,
                    scrollerHeightStatus: LOADING
                };
            }
            else if (action.status === ERROR) {
                   return {
                    ...state,
                    message: action?.payload?.message,
                    errors: action?.payload?.errors,
                    manageWatchMark: action.payload,
                    manageWatchMarkStatus: action.status,
                    scrollerHeightStatus: LOADING
                };
            }
            else {
                return{
                    ...state,
                        manageWatchMarkStatus: action.status,
                }
            }

        case MANAGE_MANAGER_MARK:
            if (action.status === SUCCESS) {
                return {
                    ...state,
                    message: action.payload.message,
                    errors:action.payload.errors,
                    manageManagerMark : action.payload,
                    manageManagerMarkStatus : action.status
                }
            }
            else if (action.status === ERROR) {
                  return {
                    ...state,
                    message: action.payload.message,
                    errors:action.payload.errors,
                    manageManagerMark : action.payload,
                    manageManagerMarkStatus: action.status,
                    scrollerHeightStatus:action.status
                
                }
            }
              else {
                return{
                    ...state,
                       manageManagerMarkStatus: action.status,
                }
            }
        case PROFILE_ERRORS_MESSAGES:
            return {
                ...state,
                message: '',
                errors: [],
                scrollerHeightStatus:action.status,
            }

        default:
            return state;
    }
}