import { GET_PROFILE_WATCH_REPORT, WATCH_AUTOMATION_ACTION } from "../actions/types";

export default function (state = { 
    profileWatchReport: null,
    profileWatchReportStatus: null,
    watchAutomationMsg: null,
    watchAutomationStatus: null,
}, action){
    switch (action.type) {  
        case GET_PROFILE_WATCH_REPORT:
            return {
                ...state,
                profileWatchReport: action.payload,
                profileWatchReportStatus: action.status,
            };

        case WATCH_AUTOMATION_ACTION:
            return {
                ...state,
                watchAutomationMsg: action.payload,
                watchAutomationStatus: action.status
            }

        default:
            return state;
    }
}