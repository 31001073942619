import React, { Component } from "react";
import { connect } from "react-redux";

import { signIn } from "../actions/actions";

class SignIn extends Component{
    constructor(props){
        super(props);
        this.state = {
            user: {
                username:"",
                password:""
            },
        };
    }

    componentDidMount(){
        if(localStorage.getItem("token")){
            this.props.history.push("/control-center");
        }
    }

    handleUserChange(userVariable, event){
        this.setState({
            user:{
                ...this.state.user,
                [userVariable]: event.target.value
            }
        });
    }

    handleSignIn(){
        const {username, password} = this.state.user;
        if(username.length>0 && password.length>0){
            this.props.signIn(this.state.user);
        } else {
            alert("Not A Super User");
        }

    }

    render(){
        return(
            <div className="Whats-New">
                {/* log In */}
                <div className="m-top-20">
                    <div className="whats-New-box">
                        <ul className="whats-New-list">
                            <li>
                                <div>
                                    <label>User Name:-</label>
                                </div>
                                <div>
                                    <input 
                                        type="text" 
                                        id="username_id"
                                        className="whats-new-input" 
                                        value={this.state.user.username} 
                                        onChange={(event)=>this.handleUserChange("username", event)} 
                                        placeholder="Enter Username"/>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <label>Password:-</label>
                                </div>
                                <div>
                                    <input 
                                        type="password" 
                                        id="password_id"
                                        className="whats-new-input" 
                                        value={this.state.user.password} 
                                        onChange={(event)=>this.handleUserChange("password" ,event)} 
                                        placeholder="Enter Password"/>
                                </div>
                            </li>
                            <li>
                                <button 
                                    type="button" 
                                    className="whats-new-button" 
                                    onClick={()=>this.handleSignIn()}>
                                    Log In
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* Log In */}
            </div>
        );
    }
}

const mapDispatchToProps = {
    signIn
};

function mapStateToProps(state){
    return(
        {
            auth: state.auth
        }
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);