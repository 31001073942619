//--------LIBRARIES--------
import axios from 'axios';

//--------AUTH--------
import { AUTHENTICATE, IMAGE_SEARCH_RESULTS, REGISTRATION, GET_JOURNAL_NUMBER, WN_GET_ALERT_MSGS, LOADING, SUCCESS, ERROR, MGR_GET_PATENT_NA_REPS, MGR_GET_PATENT_MARK } from "./types";

//--------MANAGER--------
import {
    MGR_GET_NA_REPS,
    MGR_GET_MARK,
    MGR_ADD_MSG,
    MGR_DELETE_MSG,

    //--------HEARINGS--------
    HEARING_REPORT,
    HEARING_SEND_EMAIL,
} from "./types";

//--------SEARCH--------
import {
    SRCH_GET_NA_FILE_REPS,
    SRCH_GET_NA_SRCH_REPS,
    SRCH_GET_NA_PRP_REPS,
} from "./types";

//--------WATCH--------
import {
    WAT_GET_NA_REPS,
    WAT_GET_MARK,
    WAT_ADD_MSG,
    WAT_DELETE_MSG,
    WAT_GET_JOURNAL_MARK,
    WAT_JOURNAL_MARK_MSG,
} from "./types";

//--------CONTROL CENTER--------
import {
    CC_GET_CONTROL_CENTER,
    CC_SEARCH_MARK,
    CC_REFRESH_MARK,
    CC_SCRAPER_MARK
} from "./types";

//--------CEASE DESIST--------
import {
    CD_GET_MARK,
    RESET_CD_GET_MARK,
    CD_DELETE_MARK,
    CD_GET_REPORT,
    RESET_CD_GET_REPORT,
    PROFILE_ERRORS_MESSAGES
} from "./types";

//--------CONFIGS--------
import { LOGIN_URL, ROOT_URL } from "./rootConfig";

//--------GENERAL CONSTANTS--------
import {
    TM_SEARCH,
    TM_WATCH,
    TM_MANAGER,
    FILE,
    SEARCH,
    PROPRIETOR,
    WHATS_NEW_MSG
} from "./types";

// Watch Automation
import { GET_PROFILE_WATCH_REPORT, WATCH_AUTOMATION_ACTION } from './types';

// Manage Mark
import { MANAGE_WATCH_MARK, MANAGE_MANAGER_MARK } from './types';

// Auto Add trademark
import { 
    GET_ATTORNEY, 
    GET_PROPRIETOR,
    WATCH_AUTO_ADD_TRADEMARK,
    MANAGER_AUTO_ADD_TRADEMARK,
    GET_AUTO_ADD_TRADEMARK 
} from './types';

import { changeCustomDateToCalendar, checkValue } from '../functions/functions';

//--------SIGN IN--------
export function signIn(user) {
    return (dispatch) => {
        axios.post(`${LOGIN_URL}/login/`, { username: user.username, password: user.password, product: "iris" })
            .then(response => {
                dispatch({ type: AUTHENTICATE, payload: true });
                localStorage.setItem("token", response.data.token);
                window.location.pathname = '/control-center'
            }).catch(err => {
                if (err) {
                    alert(err);
                }
            });
    };
}

//--------MANAGER: GET MEGATRONS--------
export function getMegatronsMGR(actionType, subscription = null) {
    console.log("subscription", subscription);
    return (dispatch) => {
        axios.get(`${ROOT_URL}/management_megatrons/`, {
            params: {
                subscription: subscription
            },
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: actionType, payload: response.data });
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------MANAGER: SPAWN MEGATRONS--------
export function spawnMegatronsMGR({ periodic, megatron_id }) {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/management_megatrons/`, { periodic: periodic, profile_id: megatron_id }, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            this.getMegatronsMGR();
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------CONTROL CENTER: LATEST TRADEMARKS REFRESH--------
export function latestTrademarksScraperManagerCC() {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/trademark_scrapers/`, { scraper_name: "latest_trademark_refresh" }, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
        }).catch(err => {
            alert(err);
        });
    }
}

//--------CONTROL CENTER: MISSED TRADEMARKS REFRESH--------
export function missedTrademarksRefreshCC() {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/trademark_scrapers/`, { scraper_name: "missed_latest_trademarks_fetch" }, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
        }).catch(err => {
            alert(err);
        });
    }
}

//--------SEARCH|WATCH|MANAGER: GET UNAVAILABLE REPORTS--------
export function getUnavailableReports({ product_name, search_report_type }, subscription = null) {
    return (dispatch) => {
        axios.get(`${ROOT_URL}/resend_report/`, {
            params: { product_name, search_report_type, subscription },
            headers: { Authorization: 'Token ' + localStorage.getItem("token") }
        }).then(response => {
            switch (product_name) {
                case TM_SEARCH:
                    switch (search_report_type) {
                        case FILE:
                            dispatch({ type: SRCH_GET_NA_FILE_REPS, payload: response.data });
                            break;

                        case PROPRIETOR:
                            dispatch({ type: SRCH_GET_NA_PRP_REPS, payload: response.data });
                            break;

                        case SEARCH:
                            dispatch({ type: SRCH_GET_NA_SRCH_REPS, payload: response.data });
                            break;

                        default:
                            break;
                    }
                    break;

                case TM_WATCH:
                    dispatch({ type: WAT_GET_NA_REPS, payload: response.data });
                    break;

                case TM_MANAGER:
                    if(subscription === "patent"){
                        dispatch({ type: MGR_GET_PATENT_NA_REPS, payload: response.data });
                    } else {
                        dispatch({ type: MGR_GET_NA_REPS, payload: response.data });
                    }
                    break;

                default:
                    break;
            }
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    }
}

//--------SEARCH|WATCH|MANAGER: QUEUE REPORT--------
export function queueReport({ product_name, search_report_type, report_id, report_file_id = null, proprietor_flag = null, subscription }) {
    return (dispatch) => {
        switch (product_name) {
            case TM_SEARCH:
                switch (search_report_type) {
                    case FILE:
                        axios.post(
                            `${ROOT_URL}/resend_report/`,
                            {
                                product_name,
                                search_report_type,
                                report_id,
                                report_file_id,
                                proprietor_flag
                            }, {
                            headers: { Authorization: 'Token ' + localStorage.getItem("token") }
                        }).then(response => {
                        }).catch(err => {
                            if (err) {
                                alert(err);
                            }
                        });
                        break;

                    case PROPRIETOR:
                    case SEARCH:
                        axios.post(`${ROOT_URL}/resend_report/`, { product_name, search_report_type, report_id }, {
                            headers: { Authorization: 'Token ' + localStorage.getItem("token") }
                        }).then(response => {
                        }).catch(err => {
                            if (err) {
                                alert(err);
                            }
                        });
                        break;

                    default:
                        alert("")
                        break;
                }
                break;

            case TM_WATCH:
                axios.post(`${ROOT_URL}/resend_report/`, { product_name, search_report_type, custom_watch_report_id: report_id }, {
                    headers: { Authorization: 'Token ' + localStorage.getItem("token") }
                }).then(response => {
                }).catch(err => {
                    if (err) {
                        alert(err);
                    }
                });
                break;

            case TM_MANAGER:
                axios.post(`${ROOT_URL}/resend_report/`, { product_name, search_report_type, management_custom_report_id: report_id, subscription }, {
                    headers: { Authorization: 'Token ' + localStorage.getItem("token") }
                }).then(response => {
                }).catch(err => {
                    if (err) {
                        alert(err);
                    }
                });
                break;

            default:
                break;
        }
    }
}

//--------CONTROL CENTER: GET CONTROL CENTER--------
export function getControlCenter() {
    return (dispatch) => {
        axios.get(`${LOGIN_URL}/control-center/`)
            .then(response => {
                dispatch({ type: CC_GET_CONTROL_CENTER, payload: response.data });
            }).catch(err => {
                if (err) {
                    alert(err);
                }
            });
    }
}

//--------CEASE DESIST: GET CEASE DESIST MARK--------
export function getMarkCD() {
    return (dispatch) => {
        axios.get(`${ROOT_URL}/cease_desist_marks/`, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: CD_GET_MARK, payload: response.data });
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------CEASE DESIST: ADD_PROFILE_MARK--------
export function addProfileMark(mark, add) {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/cease_desist_marks/`, { term: mark.ceaseWord, application_number: mark.ceaseApplication, focus_class: mark.classList.split(",").map(x => parseInt(x)), profile_id: mark.jwp, function: add }, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: CD_GET_MARK, payload: response.data });
            this.getMarkCD();
            // alert(response.data.message)
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------CEASE DESIST: DELETE_PROFILE_MARK--------
export function deleteProfileMark(id, remove) {
    return function (dispatch) {
        axios.post(`${ROOT_URL}/cease_desist_marks/`, { function: remove, profile_id: id }, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        })
            .then(response => {
                dispatch({ type: CD_DELETE_MARK, payload: { response: response.data, profile_id: id } });
                //   alert(response.data.message)
            })
            .catch(err => {
                if (err) {
                    alert(err);
                }
            });
    }
}

//--------CEASE DESIST: RESET CEASE DESIST MARK--------
export function resetMarkCD() {
    return {
        type: RESET_CD_GET_MARK
    }
}

//--------CEASE DESIST: GET CEASE DESIST MARK--------
export function getReportCD() {
    return (dispatch) => {
        axios.get(`${ROOT_URL}/cease_desist_report/`, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: CD_GET_REPORT, payload: response.data });
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------CEASE DESIST: GET CEASE DESIST GENERATE REPORT--------
export function generateReport(reportData) {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/cease_desist_report/`, { date_from: changeCustomDateToCalendar(reportData.DateFrom), date_to: changeCustomDateToCalendar(reportData.DateTo), profile_id: reportData.jwp }, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            this.getReportCD();
            // alert(response.data.message)
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------CEASE DESIST: RESET CEASE DESIST GENERATE REPORT--------
export function resetReportCD() {
    return {
        type: RESET_CD_GET_REPORT
    }
}

//--------MANAGER: GET MANAGER MARK--------
export function getManagerMark(subscription = null) {
    return (dispatch) => {
        axios.get(`${ROOT_URL}/manager_marks/`,{
            params: {subscription},
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            if(subscription === "patent"){
                dispatch({ type: MGR_GET_PATENT_MARK, payload: response.data });
            } else {
                dispatch({ type: MGR_GET_MARK, payload: response.data });
            }
        }).catch(err =>{
            if (err) {
                alert(err);
            }
        });
    };
}

//--------MANAGER: MGR_ADD_MSG--------
export function addManagerMark(mark, markType) {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/manager_marks/`,
            {
                mm_type: mark.managerType,
                application_numbers: mark.managerApplicationNo.split(",").map(x => parseInt(x)),
                profile_id: mark.managerProfile,
                function: markType
            }, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: MGR_ADD_MSG, payload: response.data });
            this.getManagerMark();
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------MANAGER: MGR_DELETE_MSGK--------
export function deleteManagerMark(mark, markType) {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/manager_marks/`,
            {
                mm_type: mark.managerType,
                application_numbers: mark.managerApplicationNo.split(",").map(x => parseInt(x)),
                profile_id: mark.managerProfile,
                function: markType
            }, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: MGR_DELETE_MSG, payload: response.data });
            this.getManagerMark();
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------WATCH: GET WATCH MARK--------
export function getWatchMark() {
    return (dispatch) => {
        axios.get(`${ROOT_URL}/watch_marks/`, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: WAT_GET_MARK, payload: response.data });
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------WATCH: WAT_ADD_MSG--------
export function addWatchMark(mark, markType) {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/watch_marks/`, { application_numbers: mark.managerApplicationNo.split(",").map(x => parseInt(x)), profile_id: mark.managerProfile, function: markType }, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: WAT_ADD_MSG, payload: response.data });
            this.getWatchMark();
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------WATCH: WAT_DELETE_MSGK--------
export function deleteWatchMark(mark, markType) {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/watch_marks/`, { application_numbers: mark.managerApplicationNo.split(",").map(x => parseInt(x)), profile_id: mark.managerProfile, function: markType }, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: WAT_DELETE_MSG, payload: response.data });
            this.getWatchMark();
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------CONTROL CENTER: CC_SEARCH_MARKS--------
export function searchControlCenterMark(mark, markType) {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/refresh_marks/`, 
        { 
            application_numbers: mark.enterApplicationNo.split(",").map(x => parseInt(x)), 
            function: markType 
        }, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: CC_SEARCH_MARK, payload: response.data });
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------CONTROL CENTER: CC_SEARCH_MARKS--------
export function refreshControlCenterMark(mark, markType) {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/refresh_marks/`, 
        { 
            application_numbers: mark.enterRefreshApplicationNo.split(",").map(x => parseInt(x)), 
            function: markType,
            report_type: mark.selectReportType,
            report_file_name: mark.reportFileName
        }, 
        {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: CC_REFRESH_MARK, payload: response.data });
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------CONTROL CENTER: CC_SCRAPER_MARKS--------
export function scraperControlCenterMark(mark, markType) {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/refresh_marks/`, 
        { 
            application_numbers: mark.enterScraperApplicationNo.split(",").map(x => parseInt(x)), 
            function: markType 
        }, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: CC_SCRAPER_MARK, payload: response.data });
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------WATCH: GET LATEST JOURNAL MARK--------
export function getWatchJournalMark() {
    return (dispatch) => {
        axios.get(`${ROOT_URL}/latest_journal/`, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: WAT_GET_JOURNAL_MARK, payload: response.data });
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------WATCH: LATEST JOURNAL MARK MSG--------
export function msgWatchJournalMark(journalType) {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/latest_journal/`, { function: journalType }, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: WAT_JOURNAL_MARK_MSG, payload: response.data });
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------WATCH|MANAGER: DOWNLOAD MARK--------
export function downloadMark(mark) {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/download_portfolio/`, { id: mark.managerProfile, product: mark.product, subscription: mark.subscription }, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            alert(response.data.message);
            // dispatch({type: WAT_JOURNAL_MARK_MSG, payload: response.data});
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------MANAGER: HEARING--------
export function getHearingReport() {
    return (dispatch) => {
        axios.get(`${ROOT_URL}/tla_opposition_report/`, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: HEARING_REPORT, payload: response.data });
        }).catch(err => {
            if (err) {
                alert(err);
            }
        });
    };
}

//--------MANAGER: SEND EMAIL--------
export function sendEmail(EMAIL, opposition_report, tla_report) {
    return function (dispatch) {
        axios.post(`${ROOT_URL}/tla_opposition_report/`, { function: EMAIL, opposition_report: opposition_report, tla_report: tla_report }, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        })
            .then(response => {
                dispatch({ type: HEARING_SEND_EMAIL, payload: response.data });
                alert(response.data.message)
            })
            .catch(err => {
                if (err) {
                    alert(err);
                }
            });
    }
}

//--------Whats New: Create--------
export function createWhatsNew(whatsNew) {
    let formData = new FormData();
    formData.append('type', whatsNew.type);
    formData.append('title', whatsNew.title);
    formData.append('date_of_update', whatsNew.date_of_update);
    formData.append('content', whatsNew.content);
    formData.append('image', checkValue(whatsNew.image) ? whatsNew.image[0] : null);
    return (dispatch) => {
        axios.post(`${ROOT_URL}/whats_new/`, formData,
            {
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            }).then(response => {
                dispatch({ type: WHATS_NEW_MSG, payload: response.data });
            }).catch(err => {
                if (err) {
                    alert(err);
                }
            });
    };
}

//-------- Image Search: Create--------
export function createImageSearch(whatsNew) {
    let formData = new FormData();
    formData.append('image', checkValue(whatsNew.image) ? whatsNew.image[0] : null);
    return (dispatch) => {
        axios.post(`${LOGIN_URL}/image-search/`, formData,
            {
                headers: { Authorization: 'Token ' + localStorage.getItem('token') }
            }).then(response => {
                dispatch({ type: IMAGE_SEARCH_RESULTS, payload: response.data });
            }).catch(err => {
                if (err) {
                    alert(err);
                }
            });
    };
}

//--------Registration--------
export function registration(user) {
    return (dispatch) => {
        axios.post(`${LOGIN_URL}/registration/`, 
            { 
                email: user.email, 
                password: user.password, 
                confirm_password: user.confirm_password, 
                superuser_request: user.superuser_request 
            })
            .then(response => {
                dispatch({ type: REGISTRATION, payload: response.data });
                alert('Your registration has been completed successfully!');
            }).catch(err => {
                if (err) {
                    alert(err.response.data.error);
                }
            });
    };
}

//--------WATCH-AUTOMATION: GET JOURNAL PROFILE WATCH REPORT--------
export function getProfileWatchReport(report, filter_flag) {
    return (dispatch) => {
        dispatch({ type: GET_PROFILE_WATCH_REPORT, status: LOADING });
        axios.get(`${ROOT_URL}/watch_automation/`, {
            params: { 
                journal_number: filter_flag ? `${report.journal_number}`: null,
                profile: filter_flag ? `${report.profile}`: null,
                modified_date: filter_flag ? report.modified_date: null,
                pending: filter_flag ? report.pending: null,
                processing: filter_flag ? report.processing: null,
                available: filter_flag ? report.available: null,
                vulnerable_mark: filter_flag ? report.vulnerable_mark: null,
            },
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: GET_PROFILE_WATCH_REPORT, status: SUCCESS, payload: response.data });
        }).catch(err => {
            if (err) {
                alert(err);
            }
            dispatch({ type: GET_PROFILE_WATCH_REPORT, status: ERROR });
        });
    };
}

//--------Registration--------
export function watchAutomationAction(action) {
    return (dispatch) => {
        dispatch({ type: WATCH_AUTOMATION_ACTION, status: LOADING });
        axios.post(`${ROOT_URL}/watch_automation/`, 
            { 
                refresh_report: action.refresh_report,
                profile: action.refresh_report ? null : action.profile, 
                journal_number: action.refresh_report ? null : action.journal_number, 
                chunk_range:  action.refresh_report ? null : action.chunk_range, 
                notification_send:  action.refresh_report ? null : action.notification_send,
                mark_report:  action.refresh_report ? null : action.mark_report, 
                generate_report:  action.refresh_report ? null : action.generate_report, 
                process_report:  action.refresh_report ? null : action.process_report
            })
            .then(response => {
                dispatch({ type: WATCH_AUTOMATION_ACTION, status: SUCCESS, payload: response.data });
            }).catch(err => {
                if (err) {
                    alert(err);
                    dispatch({ type: WATCH_AUTOMATION_ACTION, status: ERROR });
                }
            });
    };
}

//-------Journal-Number--------
export function getJournalNumber() {
    return (dispatch) => {
        dispatch({ type: GET_JOURNAL_NUMBER, status: LOADING });
        axios.get(`${ROOT_URL}/journal_number/`, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: GET_JOURNAL_NUMBER, status: SUCCESS, payload: response.data });
        }).catch(err => {
            if (err) {
                alert(err);
            }
            dispatch({ type: GET_JOURNAL_NUMBER, status: ERROR });
        });
    };
}

//--------Post Change Alert--------
export function postChangeAlert(alertData) {
    let response;
    return async (dispatch) => {
        try {
            response = await axios.post(
                `${ROOT_URL}/alert_change/`,
                alertData,
                {
                    headers: { Authorization: 'Token ' + localStorage.getItem('token') }
                }
            );
    
            switch (response.status) {
                case 200:
                    alert("Alerts have been updated");
                    this.getChangeAlert();
                    break;
            
                default:
                    break;
            }
        } catch (err) {
            alert("Error!!!");
        }
    };
}

//--------Get Change Alert--------
export function getChangeAlert() {
    let response;
    return async (dispatch) => {
        try {
            response = await axios.get(
                `${ROOT_URL}/alert_change/`,
                {
                    headers: { Authorization: 'Token ' + localStorage.getItem('token') }
                }
            );
    
            switch (response.status) {
                case 200:
                    dispatch({type: WN_GET_ALERT_MSGS, status: SUCCESS, payload: response.data});
                    break;
            
                default:
                    alert("Error in getting alert messages!");
                    dispatch({type: WN_GET_ALERT_MSGS, status: ERROR});
                    break;
            }
        } catch (err) {
            alert("Error in getting alert messages!");
            dispatch({type: WN_GET_ALERT_MSGS, status: ERROR});
        }
    };
}

//--------MANAGE-PROFILE: ADD/REMOVE MARK--------
export function manageMarkOperation(profileType, mark) {
    return (dispatch) => {
        switch (profileType) {
            case 'watch':
                dispatch({ type: MANAGE_WATCH_MARK, status: LOADING })
                axios.post(`${ROOT_URL}/watch_marks/`,
                {
                    function: mark.watch.operation,
                    application_numbers: mark.watch.application_no.split(",").map(x => parseInt(x)),
                    profile_id: mark.watch.profile,
                }, {
                    headers: { Authorization: 'Token ' + localStorage.getItem('token')}
                }).then(response => {
                    dispatch({ type: MANAGE_WATCH_MARK, status: SUCCESS, payload: response.data });
                    this.getManagerMark();
                }).catch(err => {
                    if (err) {
                        alert(err);
                        dispatch({ type: MANAGE_WATCH_MARK, status: ERROR });
                    }
                });
                break;

            case 'manager':
                dispatch({ type: MANAGE_MANAGER_MARK, status: LOADING })
                axios.post(`${ROOT_URL}/manager_marks/`,
                {
                    function: mark.manager.operation,
                    mm_type: mark.manager.managerType,
                    application_numbers: mark.manager.application_no.split(",").map(x => parseInt(x)),
                    profile_id: mark.manager.profile,
                }, {
                    headers: { Authorization: 'Token ' + localStorage.getItem('token')}
                }).then(response => {
                    dispatch({ type: MANAGE_MANAGER_MARK, status: SUCCESS, payload: response.data });
                    this.getManagerMark();
                }).catch(err => {
                    if (err) {
                        alert(err);
                        dispatch({ type: MANAGE_MANAGER_MARK, status: ERROR });
                    }
                });
                break;
        
            default:
                break;
        }
    };
}

//-------AUTO-ADD-TRADEMARK: Get Attorney--------
export function getAttorney(attorney) {
    return async (dispatch) => {
        dispatch({ type: GET_ATTORNEY, status: LOADING });
        await axios.get(`${ROOT_URL}/auto_add_trademark/`, {
            params: { attorney },
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: GET_ATTORNEY, status: SUCCESS, payload: response.data });
            return {
                status: SUCCESS,
            }
        }).catch(err => {
            if (err) {
                alert(err);
            }
            dispatch({ type: GET_ATTORNEY, status: ERROR });
        });
    };
}

//-------AUTO-ADD-TRADEMARK: Get Proprietor--------
export function getProprietor(proprietor) {
    return async (dispatch) => {
        dispatch({ type: GET_PROPRIETOR, status: LOADING });
        await axios.get(`${ROOT_URL}/auto_add_trademark/`, {
            params: { proprietor },
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: GET_PROPRIETOR, status: SUCCESS, payload: response.data });
        }).catch(err => {
            if (err) {
                alert(err);
            }
            dispatch({ type: GET_PROPRIETOR, status: ERROR });
        });
    };
}

//--------AUTO-ADD-TRADEMARK: Auto add trademark/get application number--------
export function autoAddTrademark(operation, mark, product) {
   
    return async (dispatch) => {
        switch (operation) {
            case 'AUTO_ADD_TRADEMARK':
                switch (product){
                    case 'TM_WATCH':
                        dispatch({ type: WATCH_AUTO_ADD_TRADEMARK, status: LOADING })
                        await axios.post(`${ROOT_URL}/auto_add_trademark/`,
                        {
                            operation: operation,
                            product: product,
                            profile_id: mark.watch.profile_id,
                            attorney: mark.watch.attorney,
                            proprietor: mark.watch.proprietor,
                            proprietor_name: mark.watch.proprietor_name,
                            trademark_class: mark.watch.trademark_class,
                            day_range: mark.watch.day_range,
                            day_of_week: mark.watch.day_of_week,
                        }, {
                            headers: { Authorization: 'Token ' + localStorage.getItem('token')}
                        }).then(response => {
                            dispatch({ type: WATCH_AUTO_ADD_TRADEMARK, status: SUCCESS, payload: response.data });
                            // this.getManagerMark();
                        }).catch(err => {
                            if (err) {
                                alert(err);
                                dispatch({ type: WATCH_AUTO_ADD_TRADEMARK, status: ERROR });
                            }
                        });
                        break;
                    case 'TM_MANAGER':
                        dispatch({ type: MANAGER_AUTO_ADD_TRADEMARK, status: LOADING })
                        await axios.post(`${ROOT_URL}/auto_add_trademark/`,
                        {
                            operation: operation,
                            product: product,
                            profile_id: mark.manager.profile_id,
                            management_mark_type: mark.manager.management_mark_type,
                            attorney: mark.manager.attorney,
                            proprietor: mark.manager.proprietor,
                            proprietor_name: mark.manager.proprietor_name,
                            trademark_class: mark.manager.trademark_class,
                            day_range: mark.manager.day_range,
                            day_of_week: mark.manager.day_of_week,
                        }, {
                            headers: { Authorization: 'Token ' + localStorage.getItem('token')}
                        }).then(response => {
                            dispatch({ type: MANAGER_AUTO_ADD_TRADEMARK, status: SUCCESS, payload: response.data });
                            // this.getManagerMark();
                        }).catch(err => {
                            if (err) {
                                alert(err);
                                dispatch({ type: MANAGER_AUTO_ADD_TRADEMARK, status: ERROR });
                            }
                        });
                        break;
                
                    default:
                        break;
                }
                break;
            case 'APPLICATION_NUMBER':
                dispatch({ type: GET_AUTO_ADD_TRADEMARK, status: LOADING })
                await axios.post(`${ROOT_URL}/auto_add_trademark/`,
                {
                    operation: operation,
                    attorney: mark.trademark.attorney,
                    proprietor: mark.trademark.proprietor,
                    proprietor_name: mark.trademark.proprietor_name,
                    trademark_class: mark.trademark.trademark_class,
                    day_range: mark.trademark.day_range
                }, {
                    headers: { Authorization: 'Token ' + localStorage.getItem('token')}
                }).then(response => {
                    dispatch({ type: GET_AUTO_ADD_TRADEMARK, status: SUCCESS, payload: response.data });
                    // this.getManagerMark();
                }).catch(err => {
                    if (err) {
                        alert(err);
                        dispatch({ type: GET_AUTO_ADD_TRADEMARK, status: ERROR });
                    }
                });
                break;
        
            default:
                break;
        }
    };
}

//--------MANAGE-PROFILE: Get Manage profile--------
export function getManageProfile(actionType, product) {
    return async (dispatch) => {
        dispatch({ type: actionType, status: LOADING })
        await axios.get(`${ROOT_URL}/upload_portfolio/`, {
            params: { product },
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: actionType, status: SUCCESS, payload: response.data });
        }).catch(err => {
            if (err) {
                alert(err);
            }
            dispatch({ type: actionType, status: ERROR });
        });
    };
}

//--------MANAGE-PROFILE: Get Manage profile--------
export function manageProfileMark(actionType, mark) {
    return async (dispatch) => {
        dispatch({ type: actionType, status: LOADING })
        await axios.post(`${ROOT_URL}/upload_portfolio/`, mark, {
            headers: { Authorization: 'Token ' + localStorage.getItem('token') }
        }).then(response => {
            dispatch({ type: actionType, status: SUCCESS, payload: response.data });
        }).catch(err => {
            if (err) {
                dispatch({ type: actionType, status: ERROR, payload: err.response.data });
                // alert(err);
            }
        });
    };
}

export function handleErrorMessages() {
    return async (dispatch) => {
        dispatch({ type: PROFILE_ERRORS_MESSAGES, status: SUCCESS })
    }
}