//--------CONSTANTS--------
import { IMAGE_SEARCH_RESULTS } from "../actions/types";


export default function (state = { imageSearchData: [], imageSearchCount: 0, imageSearchPredictedWord: '' }, action) {
    switch (action.type) {
        case IMAGE_SEARCH_RESULTS:
            return {
                ...state,
                imageSearchData: action.payload.data,
                imageSearchCount: action.payload.count,
                imageSearchPredictedWord: action.payload.predicted_word
            };

        default:
            return state;
    }
}