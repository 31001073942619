import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Multiselect } from 'multiselect-react-dropdown';
import {
    getWatchMark,
    getManagerMark,
    getAttorney,
    getProprietor,
    autoAddTrademark
} from '../actions/actions';

import '../styles/manageProfile.css';
import { LOADING, SUCCESS, TRADEMARK_CLASS } from '../actions/types';

class AutoAddTrademark extends Component{
    constructor(props){
        super(props);
        this.state = {
            selectOperation: 'AUTO_ADD_TRADEMARK',
            selectProduct: 'TM_WATCH',
            application_number: {
                attorney: '',
                attorney_options: [],
                proprietor: '',
                proprietor_options: [],
                trademark_class_options: TRADEMARK_CLASS,
            },
            auto_add_trademark: {
                attorney: '',
                attorney_options: [],
                proprietor: '',
                proprietor_options: [],
                trademark_class_options: TRADEMARK_CLASS,
            },
            mark: {
                watch: {
                    profile_id: 'select-profile',
                    attorney: [],
                    proprietor: [],
                    proprietor_name: [],
                    trademark_class: [],
                    day_range: '',
                    day_of_week: '0',
                    attorney_selectedValues: [],
                    proprietor_selectedValues: [],
                    trademark_class_selectedValues:[]
                },
                manager: {
                    profile_id: 'select-profile',
                    mm_type: 'trademark',
                    attorney: [],
                    proprietor: [],
                    proprietor_name: [],
                    trademark_class: [],
                    day_range: null,
                    day_of_week: '0',
                    attorney_selectedValues: [],
                    proprietor_selectedValues: [],
                    trademark_class_selectedValues:[]
                },
                trademark: {
                    attorney: [],
                    proprietor: [],
                    proprietor_name: [],
                    trademark_class: [],
                    day_range: '',
                    attorney_selectedValues: [],
                    proprietor_selectedValues: [],
                    trademark_class_selectedValues:[]
                },
            },
        };
        this.style = {
            multiselectContainer: {
                width: "300px",
                backgroundColor: "#fff",
            },
            searchBox: {
                border: "1px solid #4f4f4f",
                "border-radius": "0px"
            },
            optionContainer: {
                border: "1px solid #4f4f4f",
            }
        };
    }

    componentDidMount(){
        if(!localStorage.getItem('token')){
            this.props.history.push('/');
        } else {
            this.props.getWatchMark()
            this.props.getManagerMark()
        }
    }

    handleProfileChange(variable, value){
        this.setState({
            [variable]: value
        })
    }

    onSearch(operation, variable, value){
        this.setState({
            [operation]: {
                ...this.state[operation],
                [variable]: value
            }
        })
    }

    async handleSearch(operation, type){
        const {attorney, proprietor} = this.state[operation];
        if(type === 'attorney'){
            if (attorney.length > 0){
                await this.props.getAttorney(attorney);
                if (this.props.attorneyStatus === SUCCESS) {
                    this.setState({
                        [operation]: {
                            ...this.state[operation],
                            showAttorney: true,
                            [`${type}_options`]: this.props.attorney.attorney 
                        }
                    })
                }
            } else {
                alert('please enter attorney name')
            }
        }
        if(type === 'proprietor'){
            if (proprietor.length > 0){
                this.setState({
                    [operation]: {
                        ...this.state[operation],
                        showProprietor: true,
                    }
                })
                await this.props.getProprietor(proprietor)
                if (this.props.proprietorStatus === SUCCESS) {
                    this.setState({
                        [operation]: {
                            ...this.state[operation],
                            showProprietor: true,
                            [`${type}_options`]: this.props.proprietor.proprietor 
                        }
                    })
                }
            } else {
                alert('please enter proprietor name')
            }
        }
    }

    handleMarkChange(preference, userVariable, value){
        this.setState({
            mark:{
                ...this.state.mark,
                [preference]: {
                    ...this.state.mark[preference],
                    [userVariable]: value
                }
            }
        });
    }

    handleMultipleSelect(preference, userVariable, selectedList, Item){
        this.setState({
            mark:{
                ...this.state.mark,
                [preference]: {
                    ...this.state.mark[preference],
                    [userVariable]: selectedList.map(i=> i.id),
                    [`${userVariable}_selectedValues`]: selectedList,
                }
            }
        });
    }

    
    handleProprietorChange(preference, userVariable, value){
        this.setState({
            mark: {
                ...this.state.mark,
                [preference]: {
                    ...this.state.mark[preference],
                    [userVariable]: value
                }
            }
        })
    }

    handleKeyPress(preference, userVariable, userVariable1, event){
        if (event.key === 'Enter') {
            this.setState({
                mark: {
                    ...this.state.mark,
                    [preference]: {
                        ...this.state.mark[preference],
                        [userVariable1]: [...this.state.mark[preference][userVariable1], event.target.value]
                    }
                }
            })
            setTimeout(() => {
                this.setState({
                    mark: {
                        ...this.state.mark,
                        [preference]: {
                            ...this.state.mark[preference],
                            [userVariable]: ''
                        }
                    }
                })
            }, 500);
        }
    }

    handleCloseTag(preference, userVariable, userVariable1, tag){
        this.setState({
            mark: {
                ...this.state.mark,
                [preference]: {
                    ...this.state.mark[preference],
                    [userVariable]: '',
                    [userVariable1]: this.state.mark[preference][userVariable1].filter(eachTag => eachTag !== tag)
                }
            }
        })
    }

    async handleSubmit(operation) {
        const { selectProduct, mark } = this.state;
        const product = (selectProduct === 'TM_WATCH' ? 'watch' : selectProduct === 'TM_MANAGER' ? 'manager' : 'watch')
        if (operation === 'AUTO_ADD_TRADEMARK') {
            if ((mark[product].profile_id !== 'select-profile') && (mark[product].day_range !== '')) {
                await this.props.autoAddTrademark(operation, mark, selectProduct);
                if ((this.props.watchAutoAddTrademarkStatus === 'SUCCESS') || (this.props.managerAutoAddTrademarkStatus === 'SUCCESS')) {
                    this.setState({
                        mark: {
                            ...this.state.mark,
                            [product] : {
                                ...this.state.mark[product],
                                profile_id: 'select-profile',
                                attorney: [],
                                proprietor: [],
                                proprietor_name: [],
                                trademark_class: [],
                                day_range: (product === 'watch') ? '' : null,
                                day_of_week: '0',
                                attorney_selectedValues: [],
                                proprietor_selectedValues: [],
                                trademark_class_selectedValues:[]
                            }
                        }
                    })
                }
            } else {
                alert('Please select profile and enter date range')
            }
        }
        else if (operation === 'APPLICATION_NUMBER') {
            const product = 'trademark';
            if (mark[product].day_range !== '') {
                await this.props.autoAddTrademark(operation, mark);
                if ((this.props.applicationNumberStatus === 'SUCCESS')) {
                    this.setState({
                        mark: {
                            ...this.state.mark,
                            [product] : {
                                ...this.state.mark[product],
                                attorney: [],
                                proprietor: [],
                                proprietor_name: [],
                                trademark_class: [],
                                day_range: '',
                                attorney_selectedValues: [],
                                proprietor_selectedValues: [],
                                trademark_class_selectedValues:[]
                            }
                        }
                    })
                }
            } else {
                alert('Please enter date range')
            }
        }
    }

    render(){
        const { 
            watchMark, 
            managerMark, 
            attorneyStatus,
            proprietorStatus,
            watchAutoAddTrademark,
            watchAutoAddTrademarkStatus,
            managerAutoAddTrademark,
            managerAutoAddTrademarkStatus,
            applicationNumber,
            applicationNumberStatus,
        } = this.props;
        const { selectOperation, selectProduct, auto_add_trademark, mark, application_number } = this.state;
        let markProfile;
        let profileList;
        let autoAddTrademarkStatus;
        let appNumStatus
        let message;

        if(selectOperation === 'AUTO_ADD_TRADEMARK') {
            if (selectProduct === 'TM_WATCH') {
                markProfile = 'watch'
                profileList = (watchMark && watchMark.profiles)
                autoAddTrademarkStatus = watchAutoAddTrademarkStatus
                message = (watchAutoAddTrademark && watchAutoAddTrademark.message)
            } 
            else if (selectProduct === 'TM_MANAGER') {
                markProfile = 'manager'
                profileList = (managerMark && managerMark.profiles)
                autoAddTrademarkStatus = managerAutoAddTrademarkStatus
                message = (managerAutoAddTrademark && managerAutoAddTrademark.message)
            }   
        } else if(selectOperation === 'APPLICATION_NUMBER') {
            markProfile = 'trademark'
            appNumStatus = applicationNumberStatus
        }
        
        return(
            <div className="auto-add-trademark">
                <div className="manage-profile-container">
                    <div className="alignR">
                        {(selectOperation === 'AUTO_ADD_TRADEMARK') &&
                            <div className="manage-profile-dropdown">
                                <select value={selectProduct} onChange={(event)=> this.handleProfileChange('selectProduct', event.target.value)}>
                                    <option value="TM_WATCH">Trademark Watch</option>
                                    <option value="TM_MANAGER">Trademark Manager</option>
                                </select>
                            </div>
                        }
                        <div className="manage-profile-dropdown">
                            <select value={selectOperation} onChange={(event)=> this.handleProfileChange('selectOperation', event.target.value)}>
                                <option value="AUTO_ADD_TRADEMARK">Auto Add Trademark</option>
                                <option value="APPLICATION_NUMBER">Application Number</option>
                            </select>
                        </div>
                    </div>
                    <div className="mark-container">
                        <form>
                            {(selectOperation === 'AUTO_ADD_TRADEMARK') &&
                                <div>
                                    <div className="mark-list">
                                        <div className="mark-list-item">
                                            <label>Profile:- </label>
                                        </div>
                                        <div className="mark-list-item">
                                            <select 
                                                value={mark[markProfile].profile}
                                                onChange={(event)=> this.handleMarkChange(markProfile, 'profile_id', event.target.value)}>
                                                <option value="select-profile">select profile</option>
                                                {profileList.length > 0 && profileList.map( eachPropfileList=> 
                                                    <option key={eachPropfileList.id} id={`profile-${eachPropfileList.id}`} value={eachPropfileList.id}>{eachPropfileList.email}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    {selectProduct === 'TM_MANAGER' &&
                                        <div className="mark-list">
                                            <div className="mark-list-item">
                                                <label>Management Mark Type:- </label>
                                            </div>
                                            <div className="mark-list-item">
                                                <select 
                                                    value={mark[markProfile].management_mark_type}
                                                    onChange={(event)=>this.handleMarkChange(markProfile, 'management_mark_type', event.target.value)}>
                                                    <option key='trademark-add' id='trademark-add-id' value='trademark'>Your-Trademark</option>
                                                    <option key='opposed-add' id='opposed-add-id' value='opposed_trademark'>Opposed-Trademark</option>
                                                    <option key='tracker-add' id='tracker-add-id' value='status_tracker'>Status Tracker</option>
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    <div className="mark-list">
                                        <div className="mark-list-item">
                                            <label>Attorney:- </label>
                                        </div>
                                        <div className="mark-list-item">
                                            <Multiselect
                                                id="multiselect-attorney"
                                                placeholder="Select Attorney" 
                                                displayValue="name"
                                                emptyRecordMsg="No Attorney available"
                                                closeOnSelect={false}
                                                avoidHighlightFirstOption={true}
                                                style={this.style}
                                                options={auto_add_trademark.attorney_options}
                                                selectedValues={mark[markProfile].attorney_selectedValues}
                                                onSearch={(value)=> this.onSearch('auto_add_trademark', 'attorney', value)}
                                                onSelect={(selectedList, selectedItem) => this.handleMultipleSelect(markProfile, 'attorney', selectedList, selectedItem)} 
                                                onRemove={(selectedList, removedItem) => this.handleMultipleSelect(markProfile, 'attorney', selectedList, removedItem)}
                                            />
                                            <button
                                                disabled={attorneyStatus === LOADING}
                                                className="search-button search-button-position"
                                                type="button"
                                                onClick={()=> this.handleSearch('auto_add_trademark', 'attorney')}
                                                >{attorneyStatus === LOADING ? 'Loading...': 'Search'}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mark-list">
                                        <div className="mark-list-item">
                                            <label>Proprietor:- </label>
                                        </div>
                                        <div className="mark-list-item">
                                            <Multiselect
                                                id="multiselect-attorney"
                                                placeholder="Select Proprietor"
                                                displayValue="name"
                                                emptyRecordMsg="No Proprietor available"
                                                closeOnSelect={false}
                                                avoidHighlightFirstOption={true}
                                                style={this.style}
                                                options={auto_add_trademark.proprietor_options}
                                                selectedValues={mark[markProfile].proprietor_selectedValues}
                                                onSearch={(value)=> this.onSearch('auto_add_trademark', 'proprietor', value)}
                                                onSelect={(selectedList, selectedItem) => this.handleMultipleSelect(markProfile, 'proprietor', selectedList, selectedItem)} 
                                                onRemove={(selectedList, removedItem) => this.handleMultipleSelect(markProfile, 'proprietor', selectedList, removedItem)}
                                             />
                                            <button
                                                disabled={proprietorStatus === LOADING}
                                                className="search-button search-button-position"
                                                type="button"
                                                onClick={()=> this.handleSearch('auto_add_trademark', 'proprietor')}
                                                >{proprietorStatus === LOADING ? 'Loading...': 'Search'}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mark-list">
                                        <div className="mark-list-item">
                                            <label>Proprietor Name:- </label>
                                        </div>
                                        <div className="mark-list-item">
                                            <div className="tag-container">
                                                { mark[markProfile].proprietor_name.length > 0 && mark[markProfile].proprietor_name.map(eachTag => 
                                                    <span key={eachTag} className="tag">
                                                        <span className="tag-name">{eachTag}</span>
                                                        <span onClick={()=> this.handleCloseTag(markProfile, 'prop_name', 'proprietor_name', eachTag)} className="tag-close">X</span>
                                                    </span>
                                                )}
                                            </div>
                                            <input
                                                value={mark[markProfile].prop_name}
                                                onChange={(event)=> this.handleProprietorChange(markProfile, 'prop_name', event.target.value)}
                                                onKeyPress={(event)=> this.handleKeyPress(markProfile, 'prop_name', 'proprietor_name', event)}
                                            />
                                        </div>
                                    </div>
                                    <div className="mark-list">
                                        <div className="mark-list-item">
                                            <label>Trademark Class:- </label>
                                        </div>
                                        <div className="mark-list-item">
                                            <Multiselect
                                                id="multiselect-class"
                                                placeholder="Select Trademark"
                                                displayValue="name"
                                                emptyRecordMsg="No Trademark class available"
                                                closeOnSelect={false}
                                                avoidHighlightFirstOption={true}
                                                style={this.style}
                                                options={auto_add_trademark.trademark_class_options}
                                                selectedValues={mark[markProfile].trademark_class_selectedValues}
                                                onSelect={(selectedList, selectedItem) => this.handleMultipleSelect(markProfile, 'trademark_class', selectedList, selectedItem)} 
                                                onRemove={(selectedList, removedItem) => this.handleMultipleSelect(markProfile, 'trademark_class', selectedList, removedItem)}
                                            />
                                        </div>
                                    </div>
                                    <div className="mark-list">
                                        <div className="mark-list-item">
                                            <label>Day Range:- </label>
                                        </div>
                                        <div className="mark-list-item">
                                            <input 
                                                type="number" 
                                                value={mark[markProfile].day_range}
                                                onChange={(event)=> this.handleMarkChange(markProfile, 'day_range', parseInt(event.target.value))}/>
                                        </div>
                                    </div>
                                    <div className="mark-list">
                                        <div className="mark-list-item">
                                            <label>Day of Week:- </label>
                                        </div>
                                        <div className="mark-list-item">
                                            <select 
                                                value={mark[markProfile].day_of_week}
                                                onChange={(event)=> this.handleMarkChange(markProfile, 'day_of_week',parseInt(event.target.value))}>
                                                <option value="0">Sunday</option>
                                                <option value="1">Monday</option>
                                                <option value="2">Tuesday</option>
                                                <option value="3">Wednesday</option>
                                                <option value="4">Thursday</option>
                                                <option value="5">Friday</option>
                                                <option value="6">Saturday</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mark-list">
                                        <div className="mark-list-item">
                                            <label>Message:- </label>
                                        </div>
                                        <div className="mark-list-item">
                                            <textarea value={message}>{message}</textarea>
                                        </div>
                                    </div>
                                    <div className="mark-list">
                                        <button 
                                            disabled={autoAddTrademarkStatus === LOADING}
                                            type="button" 
                                            onClick={()=>this.handleSubmit('AUTO_ADD_TRADEMARK')}>
                                            {autoAddTrademarkStatus === LOADING ? 'Loading...':'Auto Add Trademark'}
                                        </button>
                                    </div>
                                </div>
                            }
                            {(selectOperation === 'APPLICATION_NUMBER') &&
                                <div>
                                    <div className="mark-list">
                                        <div className="mark-list-item">
                                            <label>Attorney:- </label>
                                        </div>
                                        <div className="mark-list-item">
                                            <Multiselect
                                                id="multiselect-attorney1"
                                                placeholder="Select Attorney" 
                                                displayValue="name"
                                                emptyRecordMsg="No Attorney available"
                                                closeOnSelect={false}
                                                avoidHighlightFirstOption={true}
                                                style={this.style}
                                                options={application_number.attorney_options}
                                                selectedValues={mark[markProfile].attorney_selectedValues}
                                                onSearch={(value)=> this.onSearch('application_number', 'attorney', value)}
                                                onSelect={(selectedList, selectedItem) => this.handleMultipleSelect(markProfile, 'attorney', selectedList, selectedItem)} 
                                                onRemove={(selectedList, removedItem) => this.handleMultipleSelect(markProfile, 'attorney', selectedList, removedItem)}
                                            />
                                            <button
                                                disabled={attorneyStatus === LOADING}
                                                className="search-button search-button-position"
                                                type="button"
                                                onClick={()=> this.handleSearch('application_number', 'attorney')}
                                                >{attorneyStatus === LOADING ? 'Loading...': 'Search'}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mark-list">
                                        <div className="mark-list-item">
                                            <label>Proprietor:- </label>
                                        </div>
                                        <div className="mark-list-item">
                                            <Multiselect
                                                id="multiselect-attorney1"
                                                placeholder="Select Proprietor"
                                                displayValue="name"
                                                emptyRecordMsg="No Proprietor available"
                                                closeOnSelect={false}
                                                avoidHighlightFirstOption={true}
                                                style={this.style}
                                                options={application_number.proprietor_options}
                                                selectedValues={mark[markProfile].proprietor_selectedValues}
                                                onSearch={(value)=> this.onSearch('application_number', 'proprietor', value)}
                                                onSelect={(selectedList, selectedItem) => this.handleMultipleSelect(markProfile, 'proprietor', selectedList, selectedItem)} 
                                                onRemove={(selectedList, removedItem) => this.handleMultipleSelect(markProfile, 'proprietor', selectedList, removedItem)}
                                             />
                                            <button
                                                disabled={proprietorStatus === LOADING}
                                                className="search-button search-button-position"
                                                type="button"
                                                onClick={()=> this.handleSearch('application_number', 'proprietor')}
                                                >{proprietorStatus === LOADING ? 'Loading...': 'Search'}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mark-list">
                                        <div className="mark-list-item">
                                            <label>Proprietor Name:- </label>
                                        </div>
                                        <div className="mark-list-item">
                                            <div className="tag-container">
                                                { mark[markProfile].proprietor_name.length > 0 && mark[markProfile].proprietor_name.map(eachTag => 
                                                    <span key={eachTag} className="tag">
                                                        <span className="tag-name">{eachTag}</span>
                                                        <span onClick={()=> this.handleCloseTag(markProfile, 'prop_name', 'proprietor_name', eachTag)} className="tag-close">X</span>
                                                    </span>
                                                )}
                                            </div>
                                            <input
                                                value={mark[markProfile].prop_name}
                                                onChange={(event)=> this.handleProprietorChange(markProfile, 'prop_name', event.target.value)}
                                                onKeyPress={(event)=> this.handleKeyPress(markProfile, 'prop_name', 'proprietor_name', event)}
                                            />
                                        </div>
                                    </div>
                                    <div className="mark-list">
                                        <div className="mark-list-item">
                                            <label>Trademark Class:- </label>
                                        </div>
                                        <div className="mark-list-item">
                                            <Multiselect
                                                id="multiselect-class1"
                                                placeholder="Select Trademark"
                                                displayValue="name"
                                                emptyRecordMsg="No Trademark class available"
                                                closeOnSelect={false}
                                                avoidHighlightFirstOption={true}
                                                style={this.style}
                                                options={application_number.trademark_class_options}
                                                selectedValues={mark[markProfile].trademark_class_selectedValues}
                                                onSelect={(selectedList, selectedItem) => this.handleMultipleSelect(markProfile, 'trademark_class', selectedList, selectedItem)} 
                                                onRemove={(selectedList, removedItem) => this.handleMultipleSelect(markProfile, 'trademark_class', selectedList, removedItem)}
                                            />
                                        </div>
                                    </div>
                                    <div className="mark-list">
                                        <div className="mark-list-item">
                                            <label>Day Range:- </label>
                                        </div>
                                        <div className="mark-list-item">
                                            <input 
                                                type="number" 
                                                value={mark[markProfile].day_range}
                                                onChange={(event)=> this.handleMarkChange(markProfile, 'day_range', event.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="mark-list">
                                        <div className="mark-list-item">
                                            <label>Message:- </label>
                                        </div>
                                        <div className="mark-list-item">
                                            <textarea value={applicationNumber && applicationNumber.application_numbers.join(',')}>{applicationNumber && applicationNumber.application_numbers.join(',')}</textarea>
                                        </div>
                                    </div>
                                    <div className="mark-list">
                                        <button 
                                            disabled={appNumStatus === LOADING}
                                            type="button" 
                                            onClick={()=>this.handleSubmit('APPLICATION_NUMBER')}>
                                            {appNumStatus === LOADING ? 'Loading...':'Get Application Number'}
                                        </button>
                                    </div>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = {
    getWatchMark,
    getManagerMark,
    getAttorney,
    getProprietor,
    autoAddTrademark
};

function mapStateToProps(state){
    const { 
        attorney,
        attorneyStatus,
        proprietor,
        proprietorStatus,
        watchAutoAddTrademark,
        watchAutoAddTrademarkStatus,
        managerAutoAddTrademark,
        managerAutoAddTrademarkStatus,
        applicationNumber,
        applicationNumberStatus,
    } = state.autoAddTrademark;
    return({
            watchMark: state.watch.watchMark,
            managerMark: state.manager.managerMark,
            
            attorney,
            attorneyStatus,
            proprietor,
            proprietorStatus,
            watchAutoAddTrademark,
            watchAutoAddTrademarkStatus,
            managerAutoAddTrademark,
            managerAutoAddTrademarkStatus,
            applicationNumber,
            applicationNumberStatus,
        });
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoAddTrademark);