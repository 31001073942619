//--------LIBRARIES--------
import React, { Component } from 'react';
import { connect } from 'react-redux';

//--------ACTIONS--------
import { registration } from '../actions/actions';

//--------CUSTOM FUNCTIONS--------
import { checkValue } from "../functions/functions";

class Registration extends Component{
    constructor(props){
        super(props);
        this.state = {
            user: {
                email: '',
                password: '',
                confirm_password: '',
                superuser_request: true
            }
        };
    }

    componentDidMount(){
        if(!localStorage.getItem("token")){
            this.props.history.push("/");
        } 
    }

    handleRegistration(userVariable, value, event){
        event.preventDefault()
        this.setState({
            user: {
            ...this.state.user,
                [userVariable]: value
            }
        })
    }
 
    handleSubmit(){
        const { email, password, confirm_password } = this.state.user;
        if ( email.length > 0 && password.length > 0 && confirm_password.length > 0) {
            this.props.registration(this.state.user)
        } else {
            alert("please fill all filed");
        }
        
        // if(email.length>0 && (('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})'.test(password)) || password.length>0 ) && confirm_password.length>0){
        //     this.props.registration(this.state.user)
        // } else {
        //     alert("please fill all filed");
        // }
    }

    render(){
        return(
            <div className="Whats-New">
                {/* registration */}
                <div className="m-top-20">
                    <div className="whats-New-box">
                        <div>
                            <p className="whats-New-heading">Registration</p>
                        </div>
                        <div>
                            <p className='whats-New-text'>{checkValue(this.props.whatsNewMessage) ? this.props.whatsNewMessage : ''}</p>
                        </div>
                        <ul className="whats-New-list">
                            <li>
                                <div>
                                    <label>Email:-</label>
                                </div>
                                <div>
                                    <input 
                                        type="email" 
                                        id="email_id"
                                        className="whats-new-input" 
                                        value={this.state.user.email} 
                                        onChange={(event)=>this.handleRegistration('email', event.target.value, event)} 
                                        placeholder="Enter Email"/>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <label>Password:-</label>
                                </div>
                                <div>
                                    <input 
                                        type="password" 
                                        id="password_id"
                                        className="whats-new-input" 
                                        value={this.state.user.password} 
                                        onChange={(event)=>this.handleRegistration('password', event.target.value, event)} 
                                        placeholder="Enter Password"/>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <label>Confirm Password:-</label>
                                </div>
                                <div>
                                    <input 
                                        type="password" 
                                        id="confirm_password_id"
                                        className="whats-new-input" 
                                        value={this.state.user.confirm_password} 
                                        onChange={(event)=>this.handleRegistration('confirm_password', event.target.value, event)} 
                                        placeholder="Enter Confirm Password"/>
                                        {/* <i className="fa fa-eye" aria-hidden="true"></i>
                                        <i className="fa fa-eye-slash" aria-hidden="true"></i> */}
                                </div>
                            </li>
                            <li>
                                <div>
                                    <label>Superuser Request:-</label>
                                </div>
                                <div>
                                    <input 
                                        type="checkbox" 
                                        id="superuser_request-id" 
                                        className="whats-new-input" 
                                        style={{width: "30px"}}
                                        checked={this.state.user.superuser_request}
                                        onChange={(event) => this.setState({
                                            user: {
                                                ...this.state.user,
                                                superuser_request: event.target.checked
                                            }
                                        })}/>
                                </div>
                            </li>
                            <li>
                                <button 
                                    type="button" 
                                    className="whats-new-button" 
                                    onClick={()=>this.handleSubmit()}>
                                    Save
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* registration */}
            </div>
        )
    }

    componentWillUnmount(){
        this.setState({
            intervalIndex: clearInterval(this.state.intervalIndex)
        });
    }
}

const mapDispatchToProps = {
    registration
};

function mapStateToProps(state){
    return{
        // whatsNewMessage: state.whatsNew.whatsNewMessage,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Registration);