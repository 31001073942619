//--------LIBRARIES--------
import React, { Component } from 'react';
import { connect } from 'react-redux';

//--------ACTIONS--------
import {
    getMarkCD, addProfileMark, getReportCD, generateReport, resetMarkCD, deleteProfileMark
} from '../actions/actions';

// import { CLASS_LIST } from '../actions/types'; 

//--------Custom FUNCTIONS--------
import { standardizeDate, checkValue } from '../functions/functions';

//--------STYLES--------
import '../styles/ceaseDesist.css';

class CeaseDesist extends Component{
    constructor(props){
        super(props);
        this.state = {
            intervalIndex: setInterval( () => {
                this.props.getMarkCD();
                this.props.getReportCD();
            }, 180000),
            selected_classes: [],
            check_data: [],
            mark: {
                ceaseWord:"",
                ceaseApplication:"",
                classList: '',
                jwp: '',
            },
            reportData: {
                DateFrom:"",
                DateTo:"",
                jwp: '',
            }
        };
    }

    componentDidMount(){
        if(!localStorage.getItem("token")){
            this.props.history.push("/");
        } else {
            this.props.getMarkCD();
            this.props.getReportCD();
        }
    }

    handleMarkChange(userVariable, event){
        this.setState({
            mark:{
                ...this.state.mark,
                [userVariable]: event.target.value
            }
        });

    }
    
    handleAddMark(){
        let ADD = 'ADD'
        const {ceaseWord, classList, jwp} = this.state.mark;
        if(ceaseWord.length>0 && classList.length>0 && jwp.length>0){
            this.props.addProfileMark(this.state.mark, ADD);
        } else {
            alert("please fill all filed");
        }
    }

    handleRemoveMark (data) {
        let REMOVE = 'REMOVE'
        this.props.deleteProfileMark(data.id, REMOVE);
    }

    handleDateChange(userVariable, event){
        this.setState({
            reportData:{
                ...this.state.reportData,
                [userVariable]: event.target.value
            }
        });
    }

    handleGenerateReport(){
        const {DateFrom, DateTo, jwp} = this.state.reportData;
        if(DateFrom.length>0 && DateTo.length>0 && jwp.length>0){
            this.props.generateReport(this.state.reportData);
        } else {
            alert("please fill all filed");
        }
    }  
    
    downloadExcel(data) {
        let url = data.excel 
        checkValue(url) ? window.open(url, '_blank') : alert("No Report Found")
    }

    render(){
        
        return(
            <div className="Control-Center">
                
                {/* mark-list-start */}
                <div className="col-md-12">
                    <h1 className="Table-Title">Mark List</h1>
                    <div className="col-md-12">
                        <label className="pad-l-r-15">Word:-</label>
                        <input type="text" id="cease-word" className="form-control" onChange={(event)=>this.handleMarkChange("ceaseWord", event)}/>
                        <label className="pad-l-r-15">Application Number:-</label>
                        <input type="text" id="cease-application" className="form-control" onChange={(event)=>this.handleMarkChange("ceaseApplication", event)}/>
                        <label className="pad-l-r-15">Class:-</label>
                        <input type="text" id="cease-classList" className="form-control" style={{width: '100px'}} onChange={(event)=>this.handleMarkChange("classList", event)}/>
                        {/* <select onChange={(event)=>this.handleMarkChange("classList", event)} multiple>
                            {CLASS_LIST.map(classList=>
                                <option id={`classList-${classList}`} value={classList}>{classList}</option>
                            )}
                        </select> */}

                        <label className="pad-l-r-15">Journal Watch Profile:-</label>
                        <select onChange={(event)=>this.handleMarkChange("jwp", event)}>
                            {this.props.ceaseDesistData.profiles && this.props.ceaseDesistData.profiles.map( eachPropfileList=> 
                                <option key={eachPropfileList.id} id={`jwp-${eachPropfileList.id}`} value={eachPropfileList.id} onChange={(event)=>this.handleJWP("jwp", event)}>{eachPropfileList.email}</option>
                            )}
                        </select>
                        <span className="pad-l-r-15">
                            <button type="button" className="cursor_pointer"onClick={()=>this.handleAddMark()}>Add mark</button>
                        </span>
                    </div>
                    <div className="col-md-12 m-top-20">
                        <table className="Table">
                            <thead className="Table-Head">
                                <tr className="Table-Head-Row">
                                    <th className="Table-Head-Cell" style={{width: '5%'}}>ID</th>
                                    <th className="Table-Head-Cell" style={{width: '20%'}}>E-mail</th>
                                    <th className="Table-Head-Cell" style={{width: '65%'}}>Mark List</th>
                                    <th className="Table-Head-Cell" style={{width: '10%'}}>Clear mark</th>
                                </tr>
                            </thead>
                            <tbody className="Table-Body">
                                { this.props.ceaseDesistData.profile_marks_list && this.props.ceaseDesistData.profile_marks_list.map( eachPropfileMark =>
                                    <tr className="Table-Body-Row" key = {eachPropfileMark.id}>
                                        <td className="Table-Body-Cell">{eachPropfileMark.id}</td>
                                        <td className="Table-Body-Cell">{eachPropfileMark.email}</td>
                                        <td className="Table-Body-Cell">
                                            { eachPropfileMark.marks.map(e=> 
                                                <span key={e.id}>
                                                    {checkValue(e.application_number) ? 
                                                        <span>{e.term}-{e.application_number}, </span> : <span>{e.term}, </span>
                                                    }
                                                </span>
                                            )}
                                        </td>
                                        <td className="Table-Body-Cell">
                                            <button type="button" className="cursor_pointer" onClick={()=>this.handleRemoveMark(eachPropfileMark)}>Clear</button>
                                        </td>
                                    </tr>
                                )
                                }
                                {this.props.ceaseDesistData.profile_marks_list &&  this.props.ceaseDesistData.profile_marks_list.length === 0 &&
                                    <tr className="Table-Body-Row No-Record-Row">
                                        <td colSpan="4" className="Table-Body-Cell">No Profile list found</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* mark-list-end */}

                {/* Report-list-start */}
                {/* <div className="col-md-12">
                    <h1 className="Table-Title">Report List</h1>
                    
                    <div className="col-md-12">
                        <label className="pad-l-r-15">Journal Watch Profile:-</label>
                        <select onChange={(event)=>this.handleDateChange("jwp", event)}>
                            {this.props.ceaseDesistData.profiles && this.props.ceaseDesistData.profiles.map( eachPropfileList=> 
                                <option key={eachPropfileList.id} id={`jwp-${eachPropfileList.id}`} value={eachPropfileList.id}>{eachPropfileList.email}</option>
                            )}
                        </select>
                        <label className="pad-l-r-15">Report from:-</label>
                        <input type="date" id="cease-report-from" className="form-control" onChange={(event)=>this.handleDateChange("DateFrom", event)}/>
                        <label className="pad-l-r-15">Report to:-</label>
                        <input type="date" id="cease-report-to" className="form-control" onChange={(event)=>this.handleDateChange("DateTo", event)}/>
                        <span className="pad-l-r-15">
                            <button type="button" className="cursor_pointer" onClick={()=> this.handleGenerateReport()}>Generate Report</button>
                        </span>
                    </div>
                    
                    <div className="col-md-12 m-top-20">
                        <table className="Table">
                            <thead className="Table-Head">
                                <tr className="Table-Head-Row">
                                    <th className="Table-Head-Cell">ID</th>
                                    <th className="Table-Head-Cell">E-mail</th>
                                    <th className="Table-Head-Cell">Created</th>
                                    <th className="Table-Head-Cell">Modified</th>
                                    <th className="Table-Head-Cell">Report's Title</th>
                                    <th className="Table-Head-Cell">View</th>
                                </tr>
                            </thead>
                            <tbody className="Table-Body">
                                { this.props.ceaseDesistReports && this.props.ceaseDesistReports.map( eachPropfileReport =>
                                <tr className="Table-Body-Row" key = {eachPropfileReport.id}>
                                    <td className="Table-Body-Cell">{eachPropfileReport.id}</td>
                                    <td className="Table-Body-Cell">{eachPropfileReport.user.email}</td>
                                    <td className="Table-Body-Cell">{standardizeDate(eachPropfileReport.created)}</td>
                                    <td className="Table-Body-Cell">{standardizeDate(eachPropfileReport.modified)}</td>
                                    <td className="Table-Body-Cell">{eachPropfileReport.title}</td>
                                    <td className="Table-Body-Cell">
                                        <button type="button" className="cursor_pointer" onClick={()=> this.downloadExcel(eachPropfileReport)}>EXCEL</button>
                                    </td>
                                </tr>
                                )}
                                { this.props.ceaseDesistReports && this.props.ceaseDesistReports.length === 0 &&
                                    <tr className="Table-Body-Row No-Record-Row">
                                        <td colSpan="6" className="Table-Body-Cell">No Report list found</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                </div> */}
                {/* Report-list-start */}

            </div>
        )
    }

    componentWillUnmount(){
        this.setState({
            intervalIndex: clearInterval(this.state.intervalIndex)
        });
    }
}

const mapDispatchToProps = {
    getMarkCD,
    addProfileMark,
    getReportCD,
    generateReport,
    resetMarkCD,
    deleteProfileMark
};

function mapStateToProps(state){
    return{
        stateRedux: state,
        ceaseDesistData: state.ceaseDesist.ceaseDesistData,
        ceaseDesistReports: state.ceaseDesist.ceaseDesistReports, 
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CeaseDesist);