//--------CONSTANTS--------
import { WHATS_NEW_MSG, WN_GET_ALERT_MSGS, SUCCESS } from "../actions/types";

const defaultState = {
    whatsNewMessage: null,
    alertMessages: []
};

export default function (state = defaultState, action){
    switch (action.type) {  
        case WHATS_NEW_MSG:
            return {
                ...state,
                whatsNewMessage: action.payload.message,
            };

        case WN_GET_ALERT_MSGS:
            switch (action.status) {
                case SUCCESS:
                    return {
                        ...state,
                        alertMessages: action.payload,
                    };
                
                default:
                    return state;
            }

        default:
            return state;
    }
}