import React, { Component } from "react";
import { connect } from "react-redux";

//--------ACTIONS--------
import { getProfileWatchReport, watchAutomationAction, getWatchMark, getJournalNumber } from "../actions/actions";

//--------STYLES--------
import '../styles/watchAutomation.css';
import { standardizeDate, checkValue } from "../functions/functions";
import { LOADING, SUCCESS, ERROR } from "../actions/types";

class WatchAutomation extends Component{
    constructor(props){
        super(props);
        this.state = {
            reports: {
                journal_number: 'SELECT JOURNAL',
                profile: 'SELECT PROFILE',
                modified_date: 'TODAY',
                pending: 'PDF',
                processing: false,
                available: false,
                vulnerable_mark: false
            },
            action: {
                refresh_report: false,
                profile: '', 
                journal_number: '', 
                chunk_range: '', 
                notification_send: true,
                mark_report: true, 
                generate_report: 'is_pdf_excel', 
                process_report: true
            }
        };
    }

    componentDidMount(){
        if(!localStorage.getItem("token")){
            this.props.history.push("/");
        } else {
            this.props.getProfileWatchReport()
            this.props.getWatchMark()
            this.props.getJournalNumber()
        }
    }

    onChangeReport(data, value) {
        this.setState({
            reports: {
                ...this.state.reports,
                [data]: value
            }
        })
    }

    handleApplyFilter(){
        const {
            journal_number,
            profile,
            modified_date,
            pending,
            processing,
            available,
            vulnerable_mark
        } = this.state.reports;
        if(
            journal_number.length > 0 || 
            profile.length > 0 || 
            modified_date.length > 0 || 
            pending.length > 0 || 
            processing || 
            available || 
            vulnerable_mark
        ){
            this.props.getProfileWatchReport(this.state.reports, true);
        } else {
            alert("please select the filter");
        } 
    }

    handleResetFilter(){
        const {
            journal_number,
            profile,
            modified_date,
            pending,
            processing,
            available,
            vulnerable_mark
        } = this.state.reports;
        if(
            journal_number.length > 0 || 
            profile.length > 0 || 
            modified_date.length > 0 || 
            pending.length > 0 || 
            processing || 
            available || 
            vulnerable_mark
        ){
            this.props.getProfileWatchReport();
            this.setState({
                reports: {
                    ...this.state.reports,
                    journal_number: 'SELECT JOURNAL',
                    profile: 'SELECT PROFILE',
                    modified_date: 'TODAY',
                    pending: 'PDF',
                    processing: false,
                    available: false,
                    vulnerable_mark: false
                }
            })
        } else {
            alert("No filter selected");
        }
    }

    automationAction(data, value){
        this.setState({
            action: {
                ...this.state.action,
                [data]: value
            }
        })
    }

    handleRefreshAllReport(){
        this.setState({
            action: {
                ...this.state.action,
                refresh_report: true
            }
        })
        setTimeout(() => {
            this.props.watchAutomationAction(this.state.action); 
        }, 500);
    }

    handleAutomationAction(){
        this.setState({
            action: {
                ...this.state.action,
                refresh_report: false
            }
        })
        setTimeout(() => {
            this.props.watchAutomationAction(this.state.action); 
        }, 500);
    }

    render(){
        console.log('state', this.state);
        const { profileWatchReport, profileWatchReportStatus } = this.props;
        const { reports, action } = this.state;
        return(
            <div className="Watch-Automation">
                <h1 className="Table-Title">Watch Automation</h1>
                {/* report-filter */}
                <div className="Watch-Automation-filter">
                    <div className="filter-list">
                        <select onChange={(event)=>this.onChangeReport("journal_number", event.target.value)}>
                            {profileWatchReport && profileWatchReport.filters.journal.map( eachJournal=> 
                                <option key={eachJournal} id={`journal_number-${eachJournal}`} value={eachJournal}>{eachJournal}</option>
                            )}
                        </select>
                    </div>
                    <div className="filter-list">
                        <select onChange={(event)=>this.onChangeReport("profile", event.target.value)}>
                            {profileWatchReport && profileWatchReport.filters.profile.map( eachPropfile=> 
                                <option key={eachPropfile} id={`profile-${eachPropfile}`} value={eachPropfile}>{eachPropfile}</option>
                            )}
                        </select>
                    </div>
                    <div className="filter-list">
                        <input type="checkbox" onChange={(event)=> this.onChangeReport('processing', event.target.checked)} checked={reports.processing}/>
                        <label>Processing</label>
                    </div>
                    <div className="filter-list">
                        <input type="checkbox" onChange={(event)=> this.onChangeReport('available', event.target.checked)} checked={reports.available}/>
                        <label>Available</label>
                    </div>
                    <div className="filter-list">
                        <select onChange={(event)=> this.onChangeReport('pending', event.target.value)} value={reports.pending}>
                            <option id="pdf-id" value="PDF">PDF</option>
                            <option id="excel-id" value="EXCEL">EXCEL</option>
                            <option id="excel-pdf-id" value="EXCEL PDF">EXCEL PDF</option>
                            <option id="both-id" value="BOTH">BOTH</option>
                        </select>
                    </div>
                    <div className="filter-list">
                        <input type="checkbox" onChange={(event)=> this.onChangeReport('vulnerable_mark', event.target.checked)} checked={reports.vulnerable_mark}/>
                        <label>Null Vulnerable Marks</label>
                    </div>
                    <div className="filter-list">
                        <select onChange={(event)=> this.onChangeReport('modified_date', event.target.value)} value={reports.modified_date}>
                            <option id="today-id" value="TODAY">TODAY</option>
                            <option id="yesterday-id" value="YESTERDAY">YESTERDAY</option>
                            <option id="yesterday-today-id" value="YESTERDAY TODAY">YESTERDAY TODAY</option>
                            <option id="week-day-id" value="PAST 7 DAYS">PAST 7 DAYS</option>
                        </select>
                    </div>
                    <div className="filter-list">
                        <input type="button" onClick={()=> this.handleApplyFilter()} value="Apply Filter"/>
                    </div>
                    <div className="filter-list">
                        <input type="button" onClick={()=> this.handleResetFilter()} value="Reset"/>
                    </div>
                </div>
                {/* report-filter */}

                {/* report-table */}
                <div className="Watch-Automation-table">
                    <table className="Table">
                        <thead className="Table-Head">
                            <tr className="Table-Head-Row">
                                <th className="Table-Head-Cell" style={{textAlign: 'center'}}>Profile</th>
                                <th className="Table-Head-Cell" style={{textAlign: 'center'}}>Journal Number</th>
                                <th className="Table-Head-Cell" style={{textAlign: 'center'}}>Processing</th>
                                <th className="Table-Head-Cell" style={{textAlign: 'center'}}>Available</th>
                                <th className="Table-Head-Cell" style={{textAlign: 'center'}}>Created</th>
                                <th className="Table-Head-Cell" style={{textAlign: 'center'}}>Modified</th>
                                <th className="Table-Head-Cell" style={{textAlign: 'center'}}>Is Cached</th>
                                <th className="Table-Head-Cell" style={{textAlign: 'center'}}>Is RB Created</th>
                                <th className="Table-Head-Cell" style={{textAlign: 'center'}}>Is All Created</th>
                                <th className="Table-Head-Cell" style={{textAlign: 'center'}}>Analyzed</th>
                                <th className="Table-Head-Cell" style={{textAlign: 'center'}}>Marks Count</th>
                                <th className="Table-Head-Cell" style={{textAlign: 'center'}}>Vulnerable Marks</th>
                                <th className="Table-Head-Cell" style={{textAlign: 'center'}}>Files</th>
                            </tr>
                        </thead>
                        <tbody>
                            {profileWatchReportStatus === LOADING &&
                                <tr className="Table-Body-Row No-Record-Row">
                                    <td colSpan="13" className="Table-Body-Cell">Loading...</td>
                                </tr>
                            }
                            { profileWatchReportStatus === SUCCESS && profileWatchReport && profileWatchReport.reports.length > 0 && profileWatchReport.reports.map(eachReport => 
                                <tr key={eachReport.id} className="Table-Body-Row">
                                    <td className="Table-Body-Cell">{eachReport.profile.email}({eachReport.profile.id})</td>
                                    <td className="Table-Body-Cell">{eachReport.journal_number}</td>
                                    <td className="Table-Body-Cell">{checkValue(eachReport.processing) ? 'True': 'False'}</td>
                                    <td className="Table-Body-Cell">{checkValue(eachReport.available) ? 'True': 'False'}</td>
                                    <td className="Table-Body-Cell">{standardizeDate(eachReport.created)}</td>
                                    <td className="Table-Body-Cell">{standardizeDate(eachReport.modified)}</td>
                                    <td className="Table-Body-Cell">{checkValue(eachReport.is_cached) ? 'True': 'False'}</td>
                                    <td className="Table-Body-Cell">{checkValue(eachReport.is_rb_created) ? 'True': 'False'}</td>
                                    <td className="Table-Body-Cell">{checkValue(eachReport.is_all_created) ? 'True': 'False'}</td>
                                    <td className="Table-Body-Cell">{eachReport.analyzed}</td>
                                    <td className="Table-Body-Cell">{eachReport.marks_count}</td>
                                    <td className="Table-Body-Cell">{eachReport.vulnerable_marks}</td>
                                    <td className="Table-Body-Cell">{eachReport.files.join(','+' ')}</td>
                                </tr>    
                            )}
                            { profileWatchReportStatus === SUCCESS && profileWatchReport && profileWatchReport.reports.length === 0 && 
                                <tr className="Table-Body-Row No-Record-Row">
                                    <td colSpan="13" className="Table-Body-Cell">No Report Found</td>
                                </tr>
                            }
                            {profileWatchReportStatus === ERROR &&
                                <tr className="Table-Body-Row No-Record-Row">
                                    <td colSpan="13" className="Table-Body-Cell">Something went wrong</td>
                               </tr>
                            }
                        </tbody>
                    </table>
                </div>
                {/* report-table */}

                <h1 className="Table-Title">Action</h1>
                <div className="Watch-Automation-filter">
                    <div className="filter-list">
                        <input type="button" value="Refresh All Report" onClick={()=> this.handleRefreshAllReport()}/>
                    </div>
                </div>
                <div className="Watch-Automation-filter">
                    <div className="filter-list">
                        <select value={action.profile} onChange={(event)=> this.automationAction('profile', event.target.value)}>
                            {this.props.watchMark && this.props.watchMark.profiles.map( eachPropfileList=> 
                                <option key={eachPropfileList.id} id={`watch-profile-${eachPropfileList.id}`} value={eachPropfileList.id}>{eachPropfileList.email}</option>
                            )}
                        </select>
                    </div>
                    <div className="filter-list">
                        <select value={action.journal_number} onChange={(event)=> this.automationAction('journal_number', event.target.value)}>
                            {this.props.journal_number && this.props.journal_number.journal.map( eachJournalList=> 
                                <option key={eachJournalList.id} id={`journal-number-${eachJournalList.id}`} value={eachJournalList.journal_number}>{eachJournalList.journal_number}</option>
                            )}
                        </select>
                    </div>
                    <div className="filter-list">
                        <label>Boost Count:- </label>
                        <input value={action.chunk_range} onChange={(event)=> this.automationAction('chunk_range', event.target.value)} type="number" placeholder="Chunk Range"/>
                    </div>
                    <div className="filter-list">
                        <input type="checkbox" checked={action.notification_send} onChange={(event)=> this.automationAction('notification_send', event.target.checked)}/>
                        <label>Notification to client</label>
                    </div>
                    <div className="filter-list">
                        <input type="checkbox" checked={action.mark_report} onChange={(event)=> this.automationAction('mark_report', event.target.checked)}/>
                        <label>Mark Report</label>
                    </div>
                    <div className="filter-list">
                        <select value={action.generate_report} onChange={(event)=> this.automationAction('generate_report', event.target.value)}>
                            <option id="generate-report-id" value="generate-report">Generate Report</option>
                            <option id="pdf-id" value="red_band">Red Band</option>
                            <option id="excel-id" value="all_class">All Class</option>
                            <option id="both-id" value="is_pdf_excel">PDF EXCEL</option>
                            <option id="both-id" value="none">None</option>
                        </select>
                    </div>
                    <div className="filter-list">
                        <input type="checkbox" checked={action.process_report} onChange={(event)=> this.automationAction('process_report', event.target.checked)}/>
                        <label>Complete Mark Report Process</label>
                    </div>
                    <div className="filter-list">
                        <input type="button" onClick={()=> this.handleAutomationAction()} value="Submit"/>
                    </div>
                </div>
            </div>
        );
    }

    componentWillUnmount(){
        this.setState({
            intervalIndex: clearInterval(this.state.intervalIndex)
        });
    }
}

const mapDispatchToProps = {
    getProfileWatchReport,
    watchAutomationAction,
    getWatchMark, 
    getJournalNumber
};

function mapStateToProps(state){
    const {
        profileWatchReport,
        profileWatchReportStatus,
        watchAutomationMsg,
        watchAutomationStatus
    } = state.watchAutomation;
    const {
        watchMark,
        journal_number
    } = state.watch;
    return({
            profileWatchReport,
            profileWatchReportStatus,
            watchAutomationMsg,
            watchAutomationStatus,
            watchMark,
            journal_number
        });
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchAutomation);