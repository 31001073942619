//--------LIBRARIES--------
import React, { Component } from 'react';
import { connect } from 'react-redux';

//--------ACTIONS--------
import {
    latestTrademarksScraperManagerCC,
    missedTrademarksRefreshCC,

    getControlCenter,
    searchControlCenterMark,
    refreshControlCenterMark,
    scraperControlCenterMark
} from '../actions/actions';

//--------STYLES--------
import '../styles/controlCenter.css';

class ControlCenter extends Component{
    constructor(props){
        super(props);
        this.state = {
            // getControlCenterInterval: setInterval(() => {
            //     this.props.getControlCenter()
            // }, 15000),
            mark:{
                enterApplicationNo: '',
                enterRefreshApplicationNo: '',
                enterScraperApplicationNo: '',
                selectReportType: 'REALTIME',
                reportFileName: ''
            }
        };
    }

    componentDidMount(){
        if(!localStorage.getItem("token")){
            this.props.history.push("/");
        } else {
            // this.props.getControlCenter();
        }
    }

    handleLatestTrademarksScraper(){
        this.props.latestTrademarksScraperManagerCC();
    }

    handleMissedTrademarksRefresh(){
        this.props.missedTrademarksRefreshCC();
    }

    handleMarkChange(userVariable, event){
        this.setState({
            mark:{
                ...this.state.mark,
                [userVariable]: event.target.value
            }
        });
    }

    handleSearchMark(){
        let markType = 'SEARCH'
        const {enterApplicationNo} = this.state.mark;
        if(enterApplicationNo.length>0){
            this.props.searchControlCenterMark(this.state.mark, markType);
        } else {
            alert("please fill all field");
        }
    }

    handleRefreshMark(){
        let markType = 'REFRESH'
        const {enterRefreshApplicationNo} = this.state.mark;
        if(enterRefreshApplicationNo.length>0){
            this.props.refreshControlCenterMark(this.state.mark, markType);
        } else {
            alert("please fill all field");
        }
    }

    handleScraperMark(){
        let markType = 'SCRAPER'
        const {enterScraperApplicationNo} = this.state.mark;
        if(enterScraperApplicationNo.length>0){
            this.props.scraperControlCenterMark(this.state.mark, markType);
        } else {
            alert("please fill all field");
        }
    }

    render(){
        const {selectReportType} = this.state.mark;
        return(
            <div className="Control-Center">
                <div className="w_100p">
                    <button className="cursor_pointer" onClick={()=>this.handleLatestTrademarksScraper()}>Latest Trademarks Scraper</button>
                    <button className="cursor_pointer" onClick={()=>this.handleMissedTrademarksRefresh()}>Fetch Missing Scraper</button>
                </div>

                {/* Search-mark */}
                <div className="w_100p m-top-20">
                    <h1 className="Table-Title">Search Mark</h1>
                    <label className="pad-l-r-15">Enter Marks:-</label>
                    <textarea rows="3" cols="30" onChange={(event)=>this.handleMarkChange("enterApplicationNo", event)} placeholder="Enter Marks"></textarea>
                    <label className="pad-l-r-15">
                        <button type="button" onClick={()=>this.handleSearchMark()} className="cursor_pointer">Search Marks</button>
                    </label>
                    <textarea rows="3" cols="30" value={this.props.controlCenterSearchMark.message}>{this.props.controlCenterSearchMark.message}</textarea>
                    </div>
                {/* Search-mark */}

                {/* Refresh-mark */}
                <div className="w_100p m-top-20">
                    <h1 className="Table-Title">Refresh Mark</h1>
                    <label className="pad-l-r-15">Enter Marks:-</label>
                    <textarea rows="3" cols="30" onChange={(event)=>this.handleMarkChange("enterRefreshApplicationNo", event)} placeholder="Enter Marks"></textarea>
                    <label className="pad-l-r-15">Report Type:-</label>
                    <select onChange={(event)=>this.handleMarkChange("selectReportType", event)}>
                        <option key="realtime" id="realtime" value='REALTIME'>Realtime</option>
                        <option key="urgent" id="urgent" value='URGENT'>Urgent</option>
                    </select>
                    <label className={`pad-l-r-15 ${selectReportType === 'REALTIME' ? 'display_none': ''}`}>Report File Name:-</label>
                    <input className={selectReportType === 'REALTIME' ? 'display_none': ''} type="text" onChange={(event)=>this.handleMarkChange("reportFileName", event)} placeholder="Enter File Name"/>
                    <label className="pad-l-r-15">
                        <button type="button" onClick={()=>this.handleRefreshMark()} className="cursor_pointer">Refresh Marks</button>
                    </label>
                    <textarea rows="3" cols="30" value={this.props.controlCenterRefreshMark.message}>{this.props.controlCenterRefreshMark.message}</textarea>
                </div>
                {/* Refresh-mark */}

                {/* Scraper-mark */}
                <div className="w_100p m-top-20">
                    <h1 className="Table-Title">Scrape Mark</h1>
                    <label className="pad-l-r-15">Enter Mark:-</label>
                    <input type="text" onChange={(event)=>this.handleMarkChange("enterScraperApplicationNo", event)} placeholder="Enter Marks"/>
                    <label className="pad-l-r-15">
                        <button type="button" onClick={()=>this.handleScraperMark()} className="cursor_pointer">Scrape Marks</button>
                    </label>
                    <textarea rows="3" cols="30" value={this.props.controlCenterScraperMark.message}>{this.props.controlCenterScraperMark.message}</textarea>
                </div>
                {/* Scraper-mark */}
                
                {/* <div className="w_100p m-top-20">
                    <iframe srcDoc={this.props.controlCenterHTML} className="Control-Center-Iframe" title="Control Center Iframe"></iframe>
                </div> */}
            </div>
        )
    }

    componentWillUnmount(){
        // this.setState({
        //     getControlCenterInterval: clearInterval(this.state.getControlCenterInterval)
        // });
    }
}

const mapDispatchToProps = {
    latestTrademarksScraperManagerCC,
    missedTrademarksRefreshCC,

    getControlCenter,
    searchControlCenterMark,
    refreshControlCenterMark,
    scraperControlCenterMark
};

function mapStateToProps(state){
    return{
        // stateRedux: state
        controlCenterHTML: state.controlCenter.controlCenterHTML,
        controlCenterSearchMark: state.controlCenter.controlCenterSearchMark,
        controlCenterRefreshMark: state.controlCenter.controlCenterRefreshMark,
        controlCenterScraperMark: state.controlCenter.controlCenterScraperMark
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ControlCenter);