//--------CONSTANTS--------
import { 
    HEARING_REPORT
} from '../actions/types';

export default function (state = {
    hearingReport: '',
    ceaseDesistReports: [],
}, action){
    switch (action.type) {
        case HEARING_REPORT:
            return {
                ...state,
                hearingReport: action.payload
            };
    
        default:
            return state;
    }
}