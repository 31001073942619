//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";

//--------ACTIONS--------
import { getUnavailableReports, queueReport } from "../actions/actions";

//--------CONSTANTS--------
import {
    TM_SEARCH,
    FILE,
    SEARCH,
    PROPRIETOR
} from "../actions/types";

//--------CUSTOM FUNTIONS--------
import { standardizeDate } from '../functions/functions';

//--------STYLES--------
import "../styles/search.css";

class Search extends Component{
    constructor(props){
        super(props);
        this.state = {
            intervalIndex: setInterval(() => {
                this.props.getUnavailableReports({ product_name: TM_SEARCH, search_report_type: SEARCH});
                this.props.getUnavailableReports({ product_name: TM_SEARCH, search_report_type: PROPRIETOR});
                this.props.getUnavailableReports({ product_name: TM_SEARCH, search_report_type: FILE});
            }, 180000)
        };
    }

    componentDidMount(){
        if(!localStorage.getItem("token")){
            this.props.history.push("/");
        } else {
            this.props.getUnavailableReports({ product_name: TM_SEARCH, search_report_type: SEARCH});
            this.props.getUnavailableReports({ product_name: TM_SEARCH, search_report_type: PROPRIETOR});
            this.props.getUnavailableReports({ product_name: TM_SEARCH, search_report_type: FILE});
        }
    }
    
    handleQueueReport(report_id, search_report_type, report_file_id = null, proprietor_flag = null){
        this.props.queueReport({
            product_name: TM_SEARCH,
            search_report_type,
            report_id,
            report_file_id,
            proprietor_flag
        });
    }

    render(){        
        return(
            <div className="Search">
                <h1 className="Table-Title">File</h1>
                <table className="Table">
                    <thead className="Table-Head">
                        <tr className="Table-Head-Row">
                            <th rowSpan="2" className="Table-Head-Cell">ID</th>
                            <th rowSpan="2" className="Table-Head-Cell">E-mail</th>
                            <th rowSpan="2" className="Table-Head-Cell">Created</th>
                            <th rowSpan="2" className="Table-Head-Cell">Modified</th>
                            <th colSpan="2" className="Table-Head-Cell">Report</th>
                            <th rowSpan="2" className="Table-Head-Cell">Report's Title</th>
                            <th rowSpan="2" className="Table-Head-Cell">Type</th>
                            <th rowSpan="2" className="Table-Head-Cell">Selected Marks</th>
                            <th rowSpan="2" className="Table-Head-Cell">File Type</th>
                            <th rowSpan="2" className="Table-Head-Cell">ReQueue</th>
                        </tr>
                        <tr>
                            <th className="Table-Head-Cell">Mark</th>
                            <th className="Table-Head-Cell">Proprietor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.fileReports.length>0 && this.props.fileReports.map( eachReport => 
                                <tr key={eachReport.id} className="Table-Body-Row">
                                    <td className="Table-Body-Cell">{eachReport.id}</td>
                                    <td className="Table-Body-Cell">{eachReport.mark_report?eachReport.mark_report.email:eachReport.proprietor_report?eachReport.proprietor_report.email:"-"}</td>
                                    <td className="Table-Body-Cell">{standardizeDate(eachReport.created)}</td>
                                    <td className="Table-Body-Cell">{standardizeDate(eachReport.modified)}</td>
                                    <td className="Table-Body-Cell">{eachReport.mark_report?"Mark":"-"}</td>
                                    <td className="Table-Body-Cell">{eachReport.proprietor_report?"Proprietor":"-"}</td>
                                    <td className="Table-Body-Cell">{eachReport.mark_report?eachReport.mark_report.title:eachReport.proprietor_report?eachReport.proprietor_report.title:"-"}</td>
                                    <td className="Table-Body-Cell">{eachReport.mark_report?eachReport.mark_report.type:eachReport.proprietor_report?eachReport.proprietor_report.type:"-"}</td>
                                    <td className="Table-Body-Cell">{eachReport.marks_count}</td>
                                    <td className="Table-Body-Cell">{eachReport.type}</td>
                                    <td className="Table-Body-Cell">
                                        <button
                                            type="button"
                                            className="cursor_pointer"
                                            onClick={
                                                () =>
                                                    this.handleQueueReport(
                                                        (eachReport.mark_report? eachReport.mark_report.id: eachReport.proprietor_report.id),
                                                        FILE,
                                                        eachReport.id,
                                                        (eachReport.mark_report? false: true)
                                                    )
                                                }
                                        >Queue</button>
                                    </td>
                                </tr>
                            )
                        }
                        {
                            this.props.fileReports.length === 0 &&
                            <tr className="Table-Body-Row No-Record-Row">
                                <td colSpan="11" className="Table-Body-Cell">No File Reports To Queue</td>
                            </tr>
                        }                        
                    </tbody>
                </table>
                <h1 className="Table-Title">Search</h1>
                <table className="Table">
                    <thead className="Table-Head">
                        <tr className="Table-Head-Row">
                            <th rowSpan="2" className="Table-Head-Cell">ID</th>
                            <th rowSpan="2" className="Table-Head-Cell">E-mail</th>
                            <th rowSpan="2" className="Table-Head-Cell">Created</th>
                            <th rowSpan="2" className="Table-Head-Cell">Modified</th>
                            <th colSpan="2" className="Table-Head-Cell">Megatrons</th>
                            <th rowSpan="2" className="Table-Head-Cell">ID | Mark</th>
                            <th rowSpan="2" className="Table-Head-Cell">Selected Marks</th>
                            <th rowSpan="2" className="Table-Head-Cell">Report's Title</th>
                            <th rowSpan="2" className="Table-Head-Cell">Type</th>
                            <th rowSpan="2" className="Table-Head-Cell">ReQueue</th>
                        </tr>
                        <tr>
                            <th className="Table-Head-Cell">ID</th>
                            <th className="Table-Head-Cell">IP</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.searchReports.length>0 && this.props.searchReports.map( eachReport => 
                                <tr key={eachReport.id} className="Table-Body-Row">
                                    <td className="Table-Body-Cell">{eachReport.id}</td>
                                    <td className="Table-Body-Cell">{eachReport.email}</td>
                                    <td className="Table-Body-Cell">{standardizeDate(eachReport.created)}</td>
                                    <td className="Table-Body-Cell">{standardizeDate(eachReport.modified)}</td>
                                    <td className="Table-Body-Cell">{eachReport.megatrons.map( eachMegatron => {return eachMegatron.instance_id?`${eachMegatron.instance_id}, `:""})}</td>
                                    <td className="Table-Body-Cell">{eachReport.megatrons.map( eachMegatron => {return eachMegatron.ip?`${eachMegatron.ip}, `:""})}</td>
                                    <td className="Table-Body-Cell">{eachReport.mark.id + " | " + eachReport.mark.term}</td>
                                    <td className="Table-Body-Cell">{eachReport.marks_count}</td>
                                    <td className="Table-Body-Cell">{eachReport.title}</td>
                                    <td className="Table-Body-Cell">{eachReport.type}</td>
                                    <td className="Table-Body-Cell">
                                    <button
                                        type="button"
                                        className="cursor_pointer"
                                        onClick={()=>this.handleQueueReport(eachReport.id, SEARCH)}
                                    >
                                        Queue
                                    </button>
                                    </td>
                                </tr>
                            )
                        }
                        {
                            this.props.searchReports.length === 0 &&
                            <tr className="Table-Body-Row No-Record-Row">
                                <td colSpan="11" className="Table-Body-Cell">No Search Reports To Queue</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <h1 className="Table-Title">Proprietors</h1>
                <table className="Table">
                    <thead className="Table-Head">
                        <tr className="Table-Head-Row">
                            <th rowSpan="2" className="Table-Head-Cell">ID</th>
                            <th rowSpan="2" className="Table-Head-Cell">E-mail</th>
                            <th rowSpan="2" className="Table-Head-Cell">Created</th>
                            <th rowSpan="2" className="Table-Head-Cell">Modified</th>
                            <th colSpan="2" className="Table-Head-Cell">Megatrons</th>
                            <th rowSpan="2" className="Table-Head-Cell">Proprietors</th>
                            <th rowSpan="2" className="Table-Head-Cell">Selected Marks</th>
                            <th rowSpan="2" className="Table-Head-Cell">Report's Title</th>
                            <th rowSpan="2" className="Table-Head-Cell">Type</th>
                            <th rowSpan="2" className="Table-Head-Cell">ReQueue</th>
                        </tr>
                        <tr>
                            <th className="Table-Head-Cell">ID</th>
                            <th className="Table-Head-Cell">IP</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.proprietorReports.length>0 && this.props.proprietorReports.map( eachReport => 
                                <tr key={eachReport.id} className="Table-Body-Row">
                                    <td className="Table-Body-Cell">{eachReport.id}</td>
                                    <td className="Table-Body-Cell">{eachReport.email}</td>
                                    <td className="Table-Body-Cell">{standardizeDate(eachReport.created)}</td>
                                    <td className="Table-Body-Cell">{standardizeDate(eachReport.modified)}</td>
                                    <td className="Table-Body-Cell">{eachReport.megatrons.map( eachMegatron => {return eachMegatron.instance_id?`${eachMegatron.instance_id}, `:""})}</td>
                                    <td className="Table-Body-Cell">{eachReport.megatrons.map( eachMegatron => {return eachMegatron.ip?`${eachMegatron.ip}, `:""})}</td>
                                    <td className="Table-Body-Cell">{eachReport.proprietors.map( eachProprietor => {return eachProprietor.name?`${eachProprietor.name}, `:""})}</td>
                                    <td className="Table-Body-Cell">{eachReport.marks_count}</td>
                                    <td className="Table-Body-Cell">{eachReport.title}</td>
                                    <td className="Table-Body-Cell">{eachReport.type}</td>
                                    <td className="Table-Body-Cell">
                                    <button
                                        type="button"
                                        className="cursor_pointer"
                                        onClick={()=>this.handleQueueReport(eachReport.id, PROPRIETOR)}
                                    >
                                        Queue
                                    </button>
                                    </td>
                                </tr>
                            )
                        }
                        {
                            this.props.proprietorReports.length === 0 &&
                            <tr className="Table-Body-Row No-Record-Row">
                                <td colSpan="11" className="Table-Body-Cell">No Proprietor Reports To Queue</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        );
    }

    componentWillUnmount(){
        this.setState({
            intervalIndex: clearInterval(this.state.intervalIndex)
        });
    }
}

const mapDispatchToProps = {
    getUnavailableReports,
    queueReport,
}

function mapStateToProps(state){
    return {
        // stateRedux: state,
        fileReports: state.search.fileReports,
        searchReports: state.search.searchReports,
        proprietorReports: state.search.proprietorReports,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);