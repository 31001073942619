//--------CONSTANTS--------
import {
    MGR_GET_MEGAS,

    MGR_GET_NA_REPS,
    MGR_GET_MARK,
    MGR_ADD_MSG,
    MGR_DELETE_MSG,
} from "../actions/types";

//--------CUSTOM FUNCTIONS--------
import { sortRecords } from '../functions/functions';

export default function (state = {
    megatrons: [],
    managerReports: [],
    managerMark: '',
    managerAddMarkMessage: '',
    managerDeleteMarkMessage: '',
}, action) {
    switch (action.type) {
        case MGR_GET_MEGAS:
            return {
                ...state,
                megatrons: action.payload.profiles
            };

        case MGR_GET_NA_REPS:
            {
                const sortedReports = sortRecords(action.payload.reports);
                return {
                    ...state,
                    managerReports: sortedReports
                }
            }

        case MGR_GET_MARK:
            return {
                ...state,
                managerMark: action.payload
            };

        case MGR_ADD_MSG:
            return {
                ...state,
                managerAddMarkMessage: action.payload
            };

        case MGR_DELETE_MSG:
            return {
                ...state,
                managerDeleteMarkMessage: action.payload
            };

        default:
            return state;
    }
}