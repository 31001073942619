//--------CONSTANTS--------
import {
    SRCH_GET_NA_PRP_REPS,
    SRCH_GET_NA_SRCH_REPS,
    SRCH_GET_NA_FILE_REPS
} from "../actions/types";

//--------CUSTOM FUNCTIONS--------
import { sortRecords } from '../functions/functions';

export default function (state = {
    fileReports: [],
    searchReports: [],
    proprietorReports: []
}, action){
    switch (action.type) {
        case SRCH_GET_NA_PRP_REPS:
            {
                const sortedReports = sortRecords(action.payload.reports);
                return {
                    ...state,
                    proprietorReports: sortedReports
                };    
            }

        case SRCH_GET_NA_SRCH_REPS:
            {
                const sortedReports = sortRecords(action.payload.reports)
                return {
                    ...state,
                    searchReports: sortedReports
                };
            }

        case SRCH_GET_NA_FILE_REPS:
            {
                const sortedReports = sortRecords(action.payload.reports)
                return {
                    ...state,
                    fileReports: sortedReports
                };
            }
    
        default:
            return state;
    }
}