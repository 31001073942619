//--------AUTH--------
export const AUTHENTICATE = 'AUTHENTICATE';

//--------MANAGER--------
export const MGR_GET_MEGAS = 'MGR_GET_MEGAS';
export const MGR_GET_PATENT_MEGAS = 'MGR_GET_PATENT_MEGAS';

export const MGR_GET_NA_REPS = 'MGR_GET_NA_REPS';
export const MGR_GET_PATENT_NA_REPS = 'MGR_GET_PATENT_NA_REPS';
export const MGR_GET_MARK = 'MGR_GET_MARK';
export const MGR_GET_PATENT_MARK = 'MGR_GET_PATENT_MARK';
export const MGR_ADD_MSG = 'MGR_ADD_MSG';
export const MGR_PATENT_ADD_MSG = 'MGR_PATENT_ADD_MSG';
export const MGR_DELETE_MSG = 'MGR_DELETE_MSG';

export const MGR_PATENT_DELETE_MSG = 'MGR_PATENT_DELETE_MSG';


//--------SEARCH--------
export const SRCH_GET_NA_PRP_REPS = 'SRCH_GET_NA_PRP_REPS';
export const SRCH_GET_NA_SRCH_REPS = 'SRCH_GET_NA_SRCH_REPS';
export const SRCH_GET_NA_FILE_REPS = 'SRCH_GET_NA_FILE_REPS';

//--------WATCH--------
export const WAT_GET_NA_REPS = 'WAT_GET_NA_REPS';
export const WAT_GET_MARK = 'WAT_GET_MARK';
export const WAT_ADD_MSG = 'WAT_ADD_MSG';
export const WAT_DELETE_MSG = 'WAT_DELETE_MSG';
export const WAT_GET_JOURNAL_MARK = 'WAT_GET_JOURNAL_MARK';
export const WAT_JOURNAL_MARK_MSG = 'WAT_JOURNAL_MARK_MSG';


//--------CONTROL CENTER--------
export const CC_GET_CONTROL_CENTER = 'CC_GET_CONTROL_CENTER';
export const CC_SEARCH_MARK = 'CC_SEARCH_MARK';
export const CC_REFRESH_MARK = 'CC_REFRESH_MARK';
export const CC_SCRAPER_MARK = 'CC_SCRAPER_MARK';

//--------CEASE DESIST--------
export const CD_GET_MARK = 'CD_GET_MARK';
export const CD_DELETE_MARK = 'CD_DELETE_MARK';
export const CD_GET_REPORT = 'CD_GET_REPORT';
export const RESET_CD_GET_MARK = 'RESET_CD_GET_MARK';
export const RESET_CD_GET_REPORT = 'RESET_CD_GET_REPORT';

//--------WHATS NEW--------
export const WHATS_NEW_MSG = 'WHATS_NEW_MSG';
export const WN_GET_ALERT_MSGS = 'WN_GET_ALERT_MSGS';
export const WN_POST_ALERT_MSGS = 'WN_POST_ALERT_MSGS';

//--------GENERAL CONSTANTS--------
export const TM_SEARCH = 'tm-search';
export const TM_WATCH = 'tm-watch';
export const TM_MANAGER = 'tm-manager';
export const PATENT = 'patent';

export const HEARING_REPORT = 'hearing_report';
export const HEARING_SEND_EMAIL = 'hearing_send_email';

export const FILE = 'file';
export const SEARCH = 'search';
export const PROPRIETOR = 'proprietor';
export const IMAGE_SEARCH_RESULTS = 'IMAGE_SEARCH_RESULTS';

export const REGISTRATION = 'REGISTRATION';

export const GET_JOURNAL_NUMBER = 'GET_JOURNAL_NUMBER';

// WATCH AUTOMATION
export const GET_PROFILE_WATCH_REPORT = 'GET_PROFILE_WATCH_REPORT';
export const WATCH_AUTOMATION_ACTION = 'WATCH_AUTOMATION_ACTION';

// AUTO_ADD_TRADEMARK
export const GET_ATTORNEY = 'GET_ATTORNEY';
export const GET_PROPRIETOR = 'GET_PROPRIETOR';
export const WATCH_AUTO_ADD_TRADEMARK = 'WATCH_AUTO_ADD_TRADEMARK';
export const MANAGER_AUTO_ADD_TRADEMARK = 'MANAGER_AUTO_ADD_TRADEMARK';
export const GET_AUTO_ADD_TRADEMARK = 'GET_AUTO_ADD_TRADEMARK';

// MANAGE PROFILE
export const GET_WATCH_PROFILE = 'GET_WATCH_PROFILE';
export const GET_MANAGER_PROFILE = 'GET_MANAGER_PROFILE';
export const MANAGE_WATCH_MARK = 'MANAGE_WATCH_MARK';
export const MANAGE_MANAGER_MARK = 'MANAGE_MANAGER_MARK';

export const MONTHS_NAMES = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const PROFILE_ERRORS_MESSAGES = "PROFILE_ERRORS_MESSAGES";

export const CLASS_LIST = [ 
                            "1", "2", "3", "4", "5", "6", "7", "8", "9", "10",
                            "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
                            "21", "22", "23", "24", "25", "26", "27", "28", "29", "30",
                            "31", "32", "33", "34", "35", "36", "37", "38", "39", "40",
                            "41", "42", "43", "44", "45", 
                        ];


export const TRADEMARK_CLASS = [ 
    {id: 1, name: "1"},{id: 2, name: "2"},{id: 3, name: "3"},{id: 4, name: "4"},{id: 5, name: "5"},
    {id: 6, name: "6"},{id: 7, name: "7"},{id: 8, name: "8"},{id: 9, name: "9"},{id: 10, name: "10"},
    {id: 11, name: "11"},{id: 12, name: "12"},{id: 13, name: "13"},{id: 14, name: "14"},{id: 15, name: "15"},
    {id: 16, name: "16"},{id: 17, name: "17"},{id: 18, name: "18"},{id: 19, name: "19"},{id: 20, name: "20"},
    {id: 21, name: "21"},{id: 22, name: "22"},{id: 23, name: "23"},{id: 24, name: "24"},{id: 25, name: "25"},
    {id: 26, name: "26"},{id: 27, name: "27"},{id: 28, name: "28"},{id: 29, name: "29"},{id: 30, name: "30"},
    {id: 31, name: "31"},{id: 32, name: "32"},{id: 33, name: "33"},{id: 34, name: "34"},{id: 35, name: "35"},
    {id: 36, name: "36"},{id: 37, name: "37"},{id: 38, name: "38"},{id: 39, name: "39"},{id: 40, name: "40"},
    {id: 41, name: "41"},{id: 42, name: "42"},{id: 43, name: "43"},{id: 44, name: "44"},{id: 45, name: "45"},
];

// [...Array(45).keys()]