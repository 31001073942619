//--------CONSTANTS--------
import { 
    CD_GET_MARK,
    CD_DELETE_MARK,
    CD_GET_REPORT, 
    RESET_CD_GET_MARK,
    RESET_CD_GET_REPORT,
} from '../actions/types';
import { sortRecords } from '../functions/functions';

export default function (state = {
    ceaseDesistData: '',
    ceaseDesistReports: [],
}, action){
    switch (action.type) {
        case CD_GET_MARK:
            return {
                ...state,
                ceaseDesistData: action.payload
            };

        case RESET_CD_GET_MARK:
            return {...state, ceaseDesistData: null}; 
            
        case CD_DELETE_MARK:
            return { ...state, ceaseDesistData:{...state.ceaseDesistData, profile_marks_list: state.ceaseDesistData.profile_marks_list.filter(item => item.id !== action.payload.profile_id)}};
          
        case CD_GET_REPORT:
            const ceaseDesistReports = sortRecords(action.payload.reports);
            return {
                ...state,
                ceaseDesistReports: ceaseDesistReports
            };

        case RESET_CD_GET_REPORT:
            return {...state, ceaseDesistReports: null}; 
    
        default:
            return state;
    }
}