//--------LIBRARIES--------
import React, { Component } from 'react';

import Sidebar from './Sidebar';
import '../../styles/header.css';

export default class Header extends Component{
    constructor(props){
        super(props);
        this.state = {
            tabLink: window.location.pathname,
            removeHeader: false,
            activeSidebar: false
        };
    }

    hangelMenuToggle(){
        this.setState({
            activeSidebar: !this.state.activeSidebar
        })
    }

    render(){
        return (
            <div className="mike-header">
                <div className="mike-header-container alignL">
                    {/* {window.location.pathname === '/' && */}
                    <div className="toggle-container" onClick={()=> this.hangelMenuToggle()}>
                        <div className={`bar1 ${this.state.activeSidebar ? 'change-bar1' : ''}`}></div>
                        <div className={`bar1 ${this.state.activeSidebar ? 'change-bar2' : ''}`}></div>
                        <div className={`bar1 ${this.state.activeSidebar ? 'change-bar3' : ''}`}></div>
                    </div>
                 {/* } */}
                    <div className="logo-container">
                        <div className="logo-heading">Mikelegal</div>
                    </div>
                </div>
                {this.state.activeSidebar && 
                    <div className={this.state.activeSidebar ? 'mike-sidebar-container' : 'mike-sidebar-container-hide'}>
                        <Sidebar 
                            // removeHeader = {this.state.removeHeader}
                        />
                    </div>
                }
            </div>
        );
    }
}