//--------LIBRARIES--------
import React, { Component } from 'react';
import { connect } from 'react-redux';

//--------ACTIONS--------
import {
    getHearingReport,
    sendEmail,
} from '../actions/actions';

//--------CUSTOM FUNCTIONS--------
import { standardizeDate, checkValue } from "../functions/functions";

class HearingReport extends Component{
    constructor(props){
        super(props);
        this.state = {
            intervalIndex: setInterval( () => {
                this.props.getHearingReport();
            }, 180000),
        };
    }

    componentDidMount(){
        if(!localStorage.getItem("token")){
            this.props.history.push("/");
        } else {
            this.props.getHearingReport();
        }
    }

    sendEmail(data, type) {
        let opposition_report=''
        let tla_report=''
        if (type === 'oppostion') {
            opposition_report = data.id
        }
        else if (type === 'tla'){
            tla_report = data.id
        }
        let EMAIL = checkValue(data.profile) ? 'EMAIL' : ''
        this.props.sendEmail(EMAIL, opposition_report, tla_report)
    }

    downloadExcel(data) {
        let url = data.excel 
        checkValue(url) ? window.open(url, '_blank') : alert("No Report Found")
    }

    render(){
        
        return(
            <div className="Hearing">
                
                {/* oppostion Hearing */}
                <div className="col-md-12">
                    <h1 className="Table-Title">Oppostion Hearing Report</h1>
                    <div className="col-md-12 m-top-20">
                        <table className="Table">
                            <thead className="Table-Head">
                                <tr className="Table-Head-Row">
                                    <th className="Table-Head-Cell" style={{width: '3%'}}>ID</th>
                                    <th className="Table-Head-Cell" style={{width: '25%'}}>Report Title</th>
                                    <th className="Table-Head-Cell" style={{width: '15%'}}>E-mail</th>
                                    <th className="Table-Head-Cell" style={{width: '15%'}}>Opposition Journal</th>
                                    <th className="Table-Head-Cell" style={{width: '7%'}}>Marks Count</th>
                                    <th className="Table-Head-Cell" style={{width: '10%'}}>Created Date</th>
                                    <th className="Table-Head-Cell" style={{width: '10%'}}>Modified Date</th>
                                    <th className="Table-Head-Cell" style={{width: '10%'}}>Send Email</th>
                                    <th className="Table-Head-Cell" style={{width: '5%'}}>Download</th>
                                </tr>
                            </thead>
                            <tbody className="Table-Body">
                                { this.props.hearingReport && this.props.hearingReport.opposition && this.props.hearingReport.opposition.map( eachHearing =>
                                    <tr className="Table-Body-Row" key = {eachHearing.id}>
                                        <td className="Table-Body-Cell">{eachHearing.id}</td>
                                        <td className="Table-Body-Cell">{eachHearing.title}</td>
                                        <td className="Table-Body-Cell">{checkValue(eachHearing.profile) ? eachHearing.profile.email: 'NA'}</td>
                                        <td className="Table-Body-Cell">{checkValue(eachHearing.opposition_journal) ? eachHearing.opposition_journal.title : 'NA'}</td>
                                        <td className="Table-Body-Cell">{eachHearing.marks_count}</td>
                                        <td className="Table-Body-Cell">{standardizeDate(eachHearing.created)}</td>
                                        <td className="Table-Body-Cell">{standardizeDate(eachHearing.modified)}</td> 
                                        <td className="Table-Body-Cell">
                                            <button type="button" className="cursor_pointer" onClick={()=>this.sendEmail(eachHearing, 'oppostion')}>Send</button>
                                        </td>
                                        <td className="Table-Body-Cell">
                                            <button type="button" className="cursor_pointer" onClick={()=>this.downloadExcel(eachHearing)}>EXCEL</button>
                                        </td>
                                    </tr>
                                )
                                }
                                {this.props.hearingReport && this.props.hearingReport.opposition &&  this.props.hearingReport.opposition.length === 0 &&
                                    <tr className="Table-Body-Row No-Record-Row">
                                        <td colSpan="7" className="Table-Body-Cell">No Oppostion Hearing Report Found</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* oppostion Hearing */}

                {/* mark-list-start */}
                <div className="col-md-12">
                    <h1 className="Table-Title">TLA Hearing Report</h1>
                    <div className="col-md-12 m-top-20">
                        <table className="Table">
                            <thead className="Table-Head">
                                <tr className="Table-Head-Row">
                                    <th className="Table-Head-Cell" style={{width: '3%'}}>ID</th>
                                    <th className="Table-Head-Cell" style={{width: '25%'}}>Report Title</th>
                                    <th className="Table-Head-Cell" style={{width: '15%'}}>E-mail</th>
                                    <th className="Table-Head-Cell" style={{width: '15%'}}>Opposition Journal</th>
                                    <th className="Table-Head-Cell" style={{width: '7%'}}>Marks Count</th>
                                    <th className="Table-Head-Cell" style={{width: '10%'}}>Created Date</th>
                                    <th className="Table-Head-Cell" style={{width: '10%'}}>Modified Date</th>
                                    <th className="Table-Head-Cell" style={{width: '10%'}}>Send Email</th>
                                    <th className="Table-Head-Cell" style={{width: '10%'}}>Download</th>
                                </tr>
                            </thead>
                            <tbody className="Table-Body">
                                { this.props.hearingReport && this.props.hearingReport.tla_reports && this.props.hearingReport.tla_reports.map( eachHearing =>
                                    <tr className="Table-Body-Row" key = {eachHearing.id}>
                                        <td className="Table-Body-Cell">{eachHearing.id}</td>
                                        <td className="Table-Body-Cell">{eachHearing.title}</td>
                                        <td className="Table-Body-Cell">{checkValue(eachHearing.profile) ? eachHearing.profile.email: 'NA'}</td>
                                        <td className="Table-Body-Cell">{checkValue(eachHearing.tla_journal) ? eachHearing.tla_journal.title : 'NA'}</td>
                                        <td className="Table-Body-Cell">{eachHearing.marks_count}</td>
                                        <td className="Table-Body-Cell">{standardizeDate(eachHearing.created)}</td>
                                        <td className="Table-Body-Cell">{standardizeDate(eachHearing.modified)}</td>
                                        <td className="Table-Body-Cell">
                                            <button type="button" className="cursor_pointer" onClick={()=>this.sendEmail(eachHearing, 'tla')}>Send</button>
                                        </td>
                                        <td className="Table-Body-Cell">
                                            <button type="button" className="cursor_pointer" onClick={()=>this.downloadExcel(eachHearing)}>EXCEL</button>
                                        </td>
                                    </tr>
                                )
                                }
                                {this.props.hearingReport && this.props.hearingReport.tla_reports &&  this.props.hearingReport.tla_reports.length === 0 &&
                                    <tr className="Table-Body-Row No-Record-Row">
                                        <td colSpan="7" className="Table-Body-Cell">No TLA Hearing Report Found</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* mark-list-end */}


            </div>
        )
    }

    componentWillUnmount(){
        this.setState({
            intervalIndex: clearInterval(this.state.intervalIndex)
        });
    }
}

const mapDispatchToProps = {
    getHearingReport,
    sendEmail
};

function mapStateToProps(state){
    return{
        stateRedux: state,
        hearingReport: state.hearing.hearingReport,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HearingReport);