//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";

//--------ACTIONS--------
import {
    getUnavailableReports,
    queueReport,
    getWatchMark,
    addWatchMark,
    deleteWatchMark,
    getWatchJournalMark,
    msgWatchJournalMark,
    downloadMark,
} from "../actions/actions";

//--------CONSTANTS--------
import {
    TM_WATCH
} from "../actions/types";

//--------Custom FUNCTIONS--------
import { standardizeDate, checkValue } from '../functions/functions';

//--------STYLES--------
import '../styles/watch.css';

class Watch extends Component{
    constructor(props){
        super(props);
        this.state = {
            intervalIndex: setInterval(() => {
                this.props.getUnavailableReports({ product_name: TM_WATCH, search_report_type: ""});
                this.props.getWatchMark()
                this.props.getWatchJournalMark()
            }, 180000),
            mark: {
                managerProfile: '',
                product: 'watch',
                subscription: 'watch'
            }
        };
    }

    componentDidMount(){
        if(!localStorage.getItem("token")){
            this.props.history.push("/");
        } else {
            this.props.getUnavailableReports({ product_name: TM_WATCH, search_report_type: ""});
            this.props.getWatchMark()
            this.props.getWatchJournalMark()
        }
    }

    handleQueueReport(reportId){
        this.props.queueReport({ product_name: TM_WATCH, search_report_type: "", report_id: reportId});
    }

    handleMarkChange(userVariable, event){
        this.setState({
            mark:{
                ...this.state.mark,
                [userVariable]: event.target.value
            }
        });
    }

    handleDownloadMark() {
        const {managerProfile, subscription} = this.state.mark;
        if(managerProfile.length>0 && subscription.length>0){
            this.props.downloadMark(this.state.mark);
        } else {
            alert("please fill all filed");
        } 
    }

    downloadPDF(data) {
        let url = data.pdf
        window.open(url, '_blank')
    }

    downloadIPIndiaReport(data) {
        let url = data.url
        window.open(url, '_blank')
    }

    render(){
        return(
            <div className="Watch">
                {/* Download-mark */}
                <div className="m-top-20">
                    <h1 className="Table-Title">Download Mark</h1>
                    <label className="pad-l-r-15">Watch Profile:-</label>
                    <select className="iris-select" onChange={(event)=>this.handleMarkChange("managerProfile", event)}>
                        <option id="select_profile" value="select_profile">Select Profile</option>
                        {this.props.watchMark.profiles && this.props.watchMark.profiles.map( eachPropfileList=> 
                            <option key={eachPropfileList.id} id={`managerProfile-${eachPropfileList.id}`} value={eachPropfileList.id}>{eachPropfileList.email}</option>
                        )}
                    </select>
                    <label className="pad-l-r-15">Watch Subscription:-</label>
                    <select className="iris-select" value={this.state.mark.subscription} onChange={(event)=>this.handleMarkChange("subscription", event)}>
                        <option id="select_watch" value="watch">Journal Watch</option>
                        <option id="select_protect" value="protect">TM Protect</option>
                    </select>
                    <label className="pad-l-r-15">
                        <button type="button" className="iris-btn" onClick={()=>this.handleDownloadMark()}>Download Mark</button>
                    </label>
                </div>
                {/* Download-mark */}
                
                {/* Watch Reports */}
                <div className="latest-journal m-top-20">
                    <h1 className="Table-Title">Watch Reports</h1>
                    <table className="Table">
                        <thead className="Table-Head">
                            <tr className="Table-Head-Row">
                                <th className="Table-Head-Cell">ID</th>
                                <th className="Table-Head-Cell">E-mail</th>
                                <th className="Table-Head-Cell">Created</th>
                                <th className="Table-Head-Cell">Modified</th>
                                <th className="Table-Head-Cell">Report's Title</th>
                                <th className="Table-Head-Cell">Journal</th>
                                <th className="Table-Head-Cell">Type</th>
                                <th className="Table-Head-Cell">Selected Marks</th>
                                <th className="Table-Head-Cell">File Type</th>
                                <th className="Table-Head-Cell">ReQueue</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.watchReports.length>0 && this.props.watchReports.map( eachReport => 
                                    <tr key={eachReport.id} className="Table-Body-Row">
                                        <td className="Table-Body-Cell">{eachReport.id}</td>
                                        <td className="Table-Body-Cell">{eachReport.profile.email}</td>
                                        <td className="Table-Body-Cell">{standardizeDate(eachReport.created)}</td>
                                        <td className="Table-Body-Cell">{standardizeDate(eachReport.modified)}</td>
                                        <td className="Table-Body-Cell">{eachReport.title}</td>
                                        <td className="Table-Body-Cell">{eachReport.journal.journal_number}</td>
                                        <td className="Table-Body-Cell">{eachReport.type}</td>
                                        <td className="Table-Body-Cell">{eachReport.marks_count}</td>
                                        <td className="Table-Body-Cell">{eachReport.file_type}</td>
                                        <td className="Table-Body-Cell"><button type="button" className="cursor_pointer" onClick={()=>this.handleQueueReport(eachReport.id)}>Queue</button></td>
                                    </tr>
                                )
                            }
                            {
                                this.props.watchReports.length === 0 &&
                                <tr className="Table-Body-Row No-Record-Row">
                                    <td colSpan="10" className="Table-Body-Cell">No File Reports To Queue</td>
                                </tr>
                            }                        
                        </tbody>
                    </table>
                </div>
                {/* Watch Reports */}

                {/* latest-journal */}
                <div className="latest-journal">
                    <h1 className="Table-Title">Latest Journal</h1>
                    <div className="latest-journal-box">
                        { this.props.watchJournalMark &&
                            <ul className="latest-journal-list">
                                <li>
                                    <label>Journal Number:- </label> 
                                    <span>{checkValue(this.props.watchJournalMark.data.journal_number) ? this.props.watchJournalMark.data.journal_number: ''}</span>
                                </li>
                                <li>
                                    <label>Published Date:- </label> 
                                    <span>{checkValue(this.props.watchJournalMark.data.published_date) ? this.props.watchJournalMark.data.published_date: ''}</span>
                                </li>
                                <li>
                                    <label>Number of Marks:- </label> 
                                    <span>{checkValue(this.props.watchJournalMark.data.marks_count) ? this.props.watchJournalMark.data.marks_count: ''}</span>
                                 </li>
                                <li>
                                    <label>Number of Missing Marks:- </label> 
                                    <span>{checkValue(this.props.watchJournalMark.data.missing_marks_count) ? this.props.watchJournalMark.data.missing_marks_count: ''}</span>
                                </li>
                                <li>
                                    <label>Missing Marks:- </label> 
                                    <span>
                                        {this.props.watchJournalMark.data.missing_trademarks.map(missingMark=> 
                                            <span>{checkValue(missingMark.mark) ? missingMark.mark: ''}</span>
                                        )}
                                        {this.props.watchJournalMark.data.missing_trademarks.length === 0 &&
                                            <span></span>
                                        }
                                    </span>
                                </li>
                                <li>
                                    <label>Sub-Journal:- </label>
                                    <div>
                                        <table className="Table">
                                            <thead className="Table-Head">
                                                <tr className="Table-Head-Row">
                                                    <th className="Table-Head-Cell" style={{width: '17%', textAlign: 'center'}}>Name</th>
                                                    <th className="Table-Head-Cell" style={{width: '35%', textAlign: 'center'}}>Unread Pages</th>
                                                    <th className="Table-Head-Cell" style={{width: '35%', textAlign: 'center'}}>Irrelevant Pages</th>
                                                    <th className="Table-Head-Cell" style={{width: '6%', textAlign: 'center'}}>PDF</th>
                                                    <th className="Table-Head-Cell" style={{width: '7%', textAlign: 'center'}}>IP India</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.props.watchJournalMark.data.sub_journals.map(subJournal=> 
                                                    <tr key={subJournal.id} className="Table-Body-Row">
                                                        <td className="Table-Body-Cell">{checkValue(subJournal.title) ? subJournal.title: ''}</td>
                                                        <td className="Table-Body-Cell">{checkValue(subJournal.unread_pages) ? subJournal.unread_pages.split(',').join(', '): ''}</td>
                                                        <td className="Table-Body-Cell">{checkValue(subJournal.irrelevant_pages) ? subJournal.irrelevant_pages.split(',').join(', '): ''}</td>
                                                        <td className="Table-Body-Cell" style={{textAlign: 'center'}}>
                                                            <button type="button" onClick={()=> this.downloadPDF(subJournal)} className="cursor_pointer">PDF</button>
                                                        </td>
                                                        <td className="Table-Body-Cell" style={{textAlign: 'center'}}>
                                                            <button type="button" onClick={()=> this.downloadIPIndiaReport(subJournal)} className="cursor_pointer">IP India</button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </li>
                            </ul>
                        }
                    </div>
                </div>
                {/* latest-journal */}

                <div className="m-top-20">
                    <label className="pad-l-r-15">
                        <button type="button" onClick={()=> this.props.msgWatchJournalMark("SCAN")} className="cursor_pointer">Scan Journal</button>
                    </label>
                    <label className="pad-l-r-15">
                        <button type="button" onClick={()=> this.props.msgWatchJournalMark("MISSING")} className="cursor_pointer">Get Missing Trademark</button>
                    </label>
                    <label className="pad-l-r-15">
                        <button type="button" onClick={()=> this.props.msgWatchJournalMark("REFRESH")} className="cursor_pointer">Refresh Journal</button>
                    </label>
                    <label className="pad-l-r-15">
                        <button type="button" onClick={()=> this.props.msgWatchJournalMark("STATUSES")} className="cursor_pointer">Get Unique Status</button>
                    </label>
                    <label className="pad-l-r-15">
                        <button type="button" onClick={()=> this.props.msgWatchJournalMark("VERIFY")} className="cursor_pointer">Verify Journal</button>
                    </label>
                    <label className="pad-l-r-15">
                        <button type="button" onClick={()=> this.props.msgWatchJournalMark("PUBLISHED")} className="cursor_pointer">Email Published Marks</button>
                    </label>
                    <textarea rows="3" cols="30" value={this.props.watchJournalMarkMsg.message}>{this.props.watchJournalMarkMsg.message}</textarea>
                </div>

            </div>
        );
    }

    componentWillUnmount(){
        this.setState({
            intervalIndex: clearInterval(this.state.intervalIndex)
        });
    }
}

const mapDispatchToProps = {
    getUnavailableReports,
    queueReport,
    getWatchMark,
    addWatchMark,
    deleteWatchMark,
    getWatchJournalMark,
    msgWatchJournalMark,
    downloadMark,
};

function mapStateToProps(state){
    return(
        {
            // stateRedux: state,
            watchReports: state.watch.watchReports,
            watchMark: state.watch.watchMark,
            watchAddMarkMessage: state.watch.watchAddMarkMessage,
            watchDeleteMarkMessage: state.watch.watchDeleteMarkMessage,
            watchJournalMark: state.watch.watchJournalMark,
            watchJournalMarkMsg: state.watch.watchJournalMarkMsg,
        }
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Watch);