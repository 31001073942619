//--------CONSTANTS--------
import {
    WAT_GET_NA_REPS,
    WAT_GET_MARK,
    WAT_ADD_MSG,
    WAT_DELETE_MSG,
    WAT_GET_JOURNAL_MARK,
    WAT_JOURNAL_MARK_MSG,
    GET_JOURNAL_NUMBER
} from "../actions/types";

//--------CUSTOM FUNCTIONS--------
import { sortRecords } from '../functions/functions';

export default function(state = {
    watchReports: [],
    watchMark: '',
    watchAddMarkMessage: '',
    watchDeleteMarkMessage: '',
    watchJournalMark: '',
    watchJournalMarkMsg: '',
    journal_number: ''
}, action){
    switch (action.type) {
        case WAT_GET_NA_REPS:
            {
                const sortedReports = sortRecords(action.payload.reports)
                return {
                    ...state,
                    watchReports: sortedReports
                }
            }

        case WAT_GET_MARK:
            return {
                ...state,
                watchMark: action.payload
            };

        case WAT_ADD_MSG:
            return {
                ...state,
                watchAddMarkMessage: action.payload
            };

        case WAT_DELETE_MSG:
            return {
                ...state,
                watchDeleteMarkMessage: action.payload
            };

        case WAT_GET_JOURNAL_MARK:
            return {
                ...state,
                watchJournalMark: action.payload
            };

        case WAT_JOURNAL_MARK_MSG:
            return {
                ...state,
                watchJournalMarkMsg: action.payload
            };

        case GET_JOURNAL_NUMBER:
            return {
                ...state,
                journal_number: action.payload
            }
    
        default:
            return state;
    }
}