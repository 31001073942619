import{ 
    GET_ATTORNEY, 
    GET_PROPRIETOR,
    WATCH_AUTO_ADD_TRADEMARK,
    MANAGER_AUTO_ADD_TRADEMARK,
    GET_AUTO_ADD_TRADEMARK 
} from "../actions/types";

export default function (state ={ 
    attorney: null,
    attorneyStatus: null,
    proprietor: null,
    proprietorStatus: null,
    watchAutoAddTrademark: null,
    watchAutoAddTrademarkStatus: null,
    managerAutoAddTrademark: null,
    managerAutoAddTrademarkStatus: null,
    applicationNumber: null,
    applicationNumberStatus: null,
}, action){
    switch (action.type) {  
        case GET_ATTORNEY:
            return {
                ...state,
                attorney: action.payload,
                attorneyStatus: action.status,
            
            }

        case GET_PROPRIETOR:
            return {
                ...state,
                proprietor: action.payload,
                proprietorStatus: action.status
            }

        case WATCH_AUTO_ADD_TRADEMARK:
            return {
                ...state,
                watchAutoAddTrademark: action.payload,
                watchAutoAddTrademarkStatus: action.status
            }

        case MANAGER_AUTO_ADD_TRADEMARK:
            return {
                ...state,
                managerAutoAddTrademark: action.payload,
                managerAutoAddTrademarkStatus: action.status
            }

        case GET_AUTO_ADD_TRADEMARK:
            return {
                ...state,
                applicationNumber: action.payload,
                applicationNumberStatus: action.status
            }

        default:
            return state;
    }
}