//--------CONSTANTS--------
import { 
    CC_GET_CONTROL_CENTER,
    CC_SEARCH_MARK,
    CC_REFRESH_MARK,
    CC_SCRAPER_MARK
 } from '../actions/types';

export default function (state = {
    controlCenterHTML: '',
    controlCenterSearchMark: '',
    controlCenterRefreshMark: '',
    controlCenterScraperMark: '',
}, action){
    switch (action.type) {
        case CC_GET_CONTROL_CENTER:
            return {
                ...state,
                controlCenterHTML: action.payload
            };

        case CC_SEARCH_MARK:
            return {
                ...state,
                controlCenterSearchMark: action.payload
            };

        case CC_REFRESH_MARK:
            return {
                ...state,
                controlCenterRefreshMark: action.payload
            };

        case CC_SCRAPER_MARK:
            return {
                ...state,
                controlCenterScraperMark: action.payload
            };
    
        default:
            return state;
    }
}