//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";

//--------ACTIONS--------
import {
    getMegatronsMGR,
    spawnMegatronsMGR,

    getUnavailableReports,
    queueReport,
    getManagerMark,
    addManagerMark,
    deleteManagerMark,
    downloadMark,
} from "../actions/actions";

//--------CONSTANTS--------
import { MGR_GET_MEGAS, TM_MANAGER } from "../actions/types";

//--------CUSTOM FUNCTIONS--------
import { standardizeDate, checkValue } from "../functions/functions";

//--------STYLES--------
import '../styles/manager.css';

class Manager extends Component{
    constructor(props){
        super(props);
        this.state = {
            intervalIndex: setInterval( () => {
                this.props.getMegatronsMGR(MGR_GET_MEGAS);
                this.props.getUnavailableReports({ product_name: TM_MANAGER, search_report_type: ""});
                this.props.getManagerMark()
            }, 180000),
            mark: {
                add: {
                    managerType: 'trademark',
                    managerApplicationNo: [],
                    managerProfile: '',
                },
                remove: {
                    managerType: 'trademark',
                    managerApplicationNo: [],
                    managerProfile: '',
                },
                download: {
                    managerProfile: '',
                    product: 'manager',
                    subscription: 'trademark'
                },
            }
        };
    }

    componentDidMount(){
        if(!localStorage.getItem("token")){
            this.props.history.push("/");
        } else {
            this.props.getMegatronsMGR(MGR_GET_MEGAS);
            this.props.getUnavailableReports({ product_name: TM_MANAGER, search_report_type: ""});
            this.props.getManagerMark()
        }
    }

    handleSingleSpawnMegatrons(data) {
        let periodic = false
        let megatron_id = data.id
        this.props.spawnMegatronsMGR({periodic, megatron_id});
    }

    handleSpawnMegatrons(){
        let periodic = true
        this.props.spawnMegatronsMGR({periodic});
    }

    handleQueueReport(reportId){
        this.props.queueReport({ product_name: TM_MANAGER, search_report_type: "", report_id: reportId, subscription: 'trademark'});
    }

    handleMarkChange(markType, userVariable, event){
        this.setState({
            mark: {
                ...this.state.mark,
                [markType]: {
                    ...this.state.mark[markType],
                    [userVariable]: event.target.value
                }
            }}
        );
    }

    handleDownloadMark() {
        const {managerProfile, subscription} = this.state.mark.download;
        if(managerProfile.length>0 && subscription.length>0){
            this.props.downloadMark(this.state.mark.download);
        } else {
            alert("please fill all filed");
        } 
    }
    
    render(){  
        return(
            <div className="Manager">
                {/* Download-mark */}
                <div className="m-top-20">
                    <h1 className="Table-Title">Download Mark</h1>
                    <label className="pad-l-r-15">Manager Profile:-</label>
                    <select className="iris-select" onChange={(event)=>this.handleMarkChange("download", "managerProfile", event)}>
                        <option id="select_profile" value="select_profile">Select Profile</option>
                        {this.props.managerMark.profiles && this.props.managerMark.profiles.map( eachPropfileList=> 
                            <option key={eachPropfileList.id} id={`managerProfile-${eachPropfileList.id}`} value={eachPropfileList.id}>{eachPropfileList.email}</option>
                        )}
                    </select>
                    <label className="pad-l-r-15">Manager Subscription:-</label>
                    <select className="iris-select" value={this.state.mark.download.subscription} onChange={(event)=>this.handleMarkChange("download", "subscription", event)}>
                        <option id="trademark" value="trademark">Trademark</option>
                        <option id="custom_trademark" value="custom_trademark">Custom Trademark</option>
                        <option id="international_trademark" value="international_trademark">International Trademark</option>
                    </select>
                    <label className="pad-l-r-15">
                        <button type="button" className="iris-btn" onClick={()=>this.handleDownloadMark()}>Download Mark</button>
                    </label>
                </div>
                {/* Download-mark */}
                
                {/* Megatrons */}
                <div className="m-top-20">
                    <h1 className="Table-Title">Megatrons</h1>
                    <table className="Table">
                        <thead className="Table-Head">
                            <tr className="Table-Head-Row">
                                <th className="Table-Head-Cell">ID</th>
                                <th className="Table-Head-Cell">E-mail</th>
                                <th className="Table-Head-Cell">Megatron ID</th>
                                <th className="Table-Head-Cell">Total Marks</th>
                                <th className="Table-Head-Cell">Remaining Marks</th>
                                <th className="Table-Head-Cell">Spawn</th>
                            </tr>
                        </thead>
                        <tbody className="Table-Body">
                            {
                                this.props.megatrons.length>0 && this.props.megatrons.map( eachMegatron =>
                                    <tr className="Table-Body-Row" key={eachMegatron.id}>
                                        <td className="Table-Body-Cell">{eachMegatron.id}</td>
                                        <td className="Table-Body-Cell">{eachMegatron.email}</td>
                                        <td className="Table-Body-Cell">{eachMegatron.megatron_instance_id}</td>
                                        <td className="Table-Body-Cell">{eachMegatron.total_marks}</td>
                                        <td className="Table-Body-Cell">{eachMegatron.remaining_marks}</td>
                                        <td className="Table-Body-Cell">
                                            <button onClick={()=>this.handleSingleSpawnMegatrons(eachMegatron)} className={` cursor_pointer ${checkValue(eachMegatron.megatron_instance_id || eachMegatron.megatron_ip) ? 'display_none': ''}`} type="button">Spawn</button>
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                this.props.megatrons.length === 0 &&
                                <tr className="Table-Body-Row No-Record-Row">
                                    <td colSpan="6" className="Table-Body-Cell">No Megatrons To Spawn</td>
                                </tr>
                            }
                            <tr className="Table-Body-Row">
                                <td colSpan="6" className="Table-Body-Cell">
                                    <button onClick={()=>this.handleSpawnMegatrons()} className="cursor_pointer">Spawn All Megatrons</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* Megatrons */}

                {/* Manager Reports */}
                <div className="m-top-20">
                    <h1 className="Table-Title">Manager Reports</h1>
                    <table className="Table">
                        <thead className="Table-Head">
                            <tr className="Table-Head-Row">
                                <th className="Table-Head-Cell">ID</th>
                                <th className="Table-Head-Cell">E-mail</th>
                                <th className="Table-Head-Cell">Created</th>
                                <th className="Table-Head-Cell">Modified</th>
                                <th className="Table-Head-Cell">Report's Title</th>
                                <th className="Table-Head-Cell">Selected Marks</th>
                                <th className="Table-Head-Cell">File Type</th>
                                <th className="Table-Head-Cell">ReQueue</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.managerReports.length>0 && this.props.managerReports.map( eachReport => 
                                    <tr key={eachReport.id} className="Table-Body-Row">
                                        <td className="Table-Body-Cell">{eachReport.id}</td>
                                        <td className="Table-Body-Cell">{eachReport.profile.email}</td>
                                        <td className="Table-Body-Cell">{standardizeDate(eachReport.created)}</td>
                                        <td className="Table-Body-Cell">{standardizeDate(eachReport.modified)}</td>
                                        <td className="Table-Body-Cell">{eachReport.title}</td>
                                        <td className="Table-Body-Cell">{eachReport.marks_count}</td>
                                        <td className="Table-Body-Cell">{eachReport.file_type}</td>
                                        <td className="Table-Body-Cell">
                                            <button onClick={()=>this.handleQueueReport(eachReport.id)} className="cursor_pointer">Queue</button>
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                this.props.managerReports.length === 0 &&
                                <tr className="Table-Body-Row No-Record-Row">
                                    <td colSpan="9" className="Table-Body-Cell">No File Reports To Queue</td>
                                </tr>
                            }                        
                        </tbody>
                    </table>
                </div>
                {/* Manager Reports */}
            </div>
        );
    }

    componentWillUnmount(){
        this.setState({
            intervalIndex: clearInterval(this.state.intervalIndex)
        });
    }
}

const mapDispatchToProps = {
    getMegatronsMGR,
    spawnMegatronsMGR,

    getUnavailableReports,
    queueReport,
    getManagerMark,
    addManagerMark,
    deleteManagerMark,
    downloadMark,
};

function mapStateToProps(state){
    return(
        {
            // stateRedux: state,
            megatrons: state.manager.megatrons,
            managerReports: state.manager.managerReports,
            managerMark: state.manager.managerMark,
            managerAddMarkMessage: state.manager.managerAddMarkMessage,
            managerDeleteMarkMessage: state.manager.managerDeleteMarkMessage
        }
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Manager);