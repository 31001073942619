import { combineReducers } from "redux";

import auth from './auth';
import manager from './manager';
import patent from './patent';
import search from './search';
import watch from './watch';
import controlCenter from './controlCenter';
import ceaseDesist from './ceaseDesist';
import hearing from './hearing';
import whatsNew from './whatsNew';
import imageSearch from './imageSearch';
import watchAutomation from './watchAutomation';
import manageProfile from './manageProfile';
import autoAddTrademark from './autoAddTrademark';

export default combineReducers(
    {
        auth,
        manager,
        patent,
        search,
        watch,
        controlCenter,
        ceaseDesist,
        hearing,
        whatsNew,
        imageSearch,
        watchAutomation,
        manageProfile,
        autoAddTrademark
    }
);